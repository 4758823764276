import {
  Box,
  Button,
  Checkbox,
  Chip,
  Fab,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Select,
  Switch,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { CloseOutlined, DeleteOutlined, EditOutlined, KeyOutlined, Visibility } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import './users.scss'
import { useEffect, useMemo, useState } from 'react'
import {
  updateUsersAdminFilters,
  useCreateUserMutation,
  useDeleteUserMutation,
  useFindUsersAdminQuery,
  useUpdatePasswordUserMutation,
  useUpdateUserMutation,
} from '../../../features/usersAdmin/userAdminSlice'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import DataExportModal from '../../../components/common/dataExport/DataExportModal'
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined'
import {
  userAdminDataMapper,
  userAdminDataMapperForPDF,
} from '../../../components/common/dataExport/dataMappers/userAdminDataMapper'
import { useTranslation } from 'react-i18next'
import { FilterModal } from '../../../components/utilities/FilterModal'
import { buildTimeStringFromTimestamp } from '../../../components/common/time/timeHelper'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import useUserTableConfig from '../../../components/hooks/useUserTableConfig'
import {
  getOrderedColumns,
  getVisibilityModel,
  updateListOrder,
} from '../../../components/common/datatable/tableUtils/tableUtils'
import CustomColumnMenu from '../../../components/common/datagrid/CustomColumnMenu'
import CustomLoadingOverlay from '../../../components/common/datagrid/CustomLoadingOverlay'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useFindRolesQuery } from '../../../features/roles/rolesSlice'
import toaster from '../../../toaster'

const Users = () => {
  const { t } = useTranslation(['common', 'messages'])
  const apiRef = useGridApiRef()
  const dispatch = useDispatch()
  const { emailClient, activeServices } = useSelector((state) => state.login)
  const queryState = useSelector((state) => state.userAdmin.filters)
  const queryStateRoles = useSelector((state) => state.role.filters)
  const [userEmail, setUserEmail] = useState(queryState?.email)
  const [page, setPage] = useState(0)
  const [roles, setRoles] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [userToReset, setUserToReset] = useState('')
  const [openReset, setOpenReset] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [email, setEmail] = useState('')
  const [openDelete, setOpenDelete] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [edit, setEdit] = useState(false)
  const [error, setError] = useState(false)
  const [idToDelete, setIdToDelete] = useState('')
  const [activeUser, setActiveUser] = useState({ id: null, email: '', status: '' })
  const [open2, setOpen2] = useState(false)
  const regex = /(^[A-Z]{4,20}(?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/
  const regexEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/

  const columns = useMemo(
    () => [
      {
        field: 'email',
        headerName: 'Email',
        width: 200,
      },
      {
        field: 'roles',
        headerName: t('common:roles'),
        width: 150,
        renderCell: (params) => {
          const rolesText = params.row.roles
            .filter((a) => a !== 'APIKEYROLE')
            .map((roleTextItem, index) => <Chip variant="transparent" label={roleTextItem} key={index} />)
          return <Box className="user-admin-chip-wrap">{rolesText}</Box>
        },
      },
      {
        field: 'services',
        headerName: t('common:services'),
        width: 400,
        renderCell: (params) => {
          return <Box className="user-admin-chip-wrap">{params.row.services ?? '---'}</Box>
        },
      },
      {
        field: 'status',
        headerName: t('common:status'),
        width: 150,
        renderCell: (params) => {
          return (
            <Box className="user-admin-flex">
              {activeServices && activeServices.includes('user:email') && (
                <Switch
                  key={params.row.id}
                  checked={params.row.status === 'ACTIVE' ? true : false}
                  onChange={(e) => {
                    handleUpdate(e, params.row)
                  }}
                />
              )}
              <p>{`${params.row.status[0].toUpperCase()}${params.row.status.slice(1).toLowerCase()}`}</p>
            </Box>
          )
        },
      },
      {
        field: 'createdAt',
        headerName: t('common:createdAt'),
        width: 200,
        renderCell: (params) => {
          return (
            <Typography variant="number">{buildTimeStringFromTimestamp(params.row.createdAt).slice(0, -4)}</Typography>
          )
        },
      },
      {
        field: 'lastLogin',
        headerName: t('common:lastLogin'),
        width: 200,
        renderCell: (params) => {
          return (
            <Typography variant="number">{buildTimeStringFromTimestamp(params.row.lastLogin).slice(0, -4)}</Typography>
          )
        },
      },
      {
        field: 'actions',
        headerName: t('common:actions'),
        hide: !activeServices,
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <Box className="user-admin-flex">
              {/* {activeServices.includes('user:email') && ( */}
              <Fab
                variant="close"
                onClick={() => {
                  setOpen(true)
                  setEdit(true)
                  setRoles(params.row.roles)
                  setActiveUser({ id: params.row._id, email: params.row.email, status: params.row.status })
                }}
              >
                <EditOutlined />
              </Fab>
              {/* )} */}

              {/* {activeServices.includes('userresetPassword') && ( */}
              <Fab
                variant="close"
                onClick={() => {
                  setOpenReset(true)
                  setUserToReset(params.row.email)
                }}
              >
                <KeyOutlined />
              </Fab>
              {/* )} */}

              {/* {params.row.email !== params.row.emailClient && activeServices.includes('user:id') && ( */}
              <Fab
                variant="close"
                onClick={() => {
                  setOpenDelete(true)
                  setActiveUser({ id: params.row._id, email: params.row.email, status: params.row.status })
                }}
              >
                <DeleteOutlined />
              </Fab>
              {/* )} */}
            </Box>
          )
        },
      },
    ],
    [],
  )

  const {
    config,
    saveConfig,
    loading: configLoading,
  } = useUserTableConfig({
    tableKey: 'userAdmin',
    defaultConfig: {
      sortModel: [{ field: 'createdTimestamp', sort: 'desc' }],
      rowsPerPage: rowsPerPage,
      pinnedColumns: { left: [], right: [] },
      columnOrder: columns?.map((c) => c.field),
      visibleColumns: columns?.reduce((acc, col) => {
        acc[col.field] = true
        return acc
      }, {}),
    },
  })

  const {
    data: usersAdminResponse,
    isLoading: isLoadingUsersAdmin,
    refetch,
    isFetching: isFetchingUsersAdmin,
  } = useFindUsersAdminQuery({
    emailClient,
    email: queryState.email,
    roles: queryState.roles,
    viewMode: queryState.viewMode,
    limit: config.rowsPerPage,
    page,
    offset: config.rowsPerPage * page,
  })

  const { usersAdmin, pagination } = useMemo(
    () => ({
      usersAdmin: usersAdminResponse?.data,
      pagination: usersAdminResponse?.pagination,
    }),
    [usersAdminResponse],
  )

  const {
    data: rolesResponse,
    isLoading: isLoadingRoles,
    isFetching: isFetchingRoles,
  } = useFindRolesQuery({
    emailClient,
    viewMode: queryStateRoles.viewMode,
    limit: queryStateRoles.limit,
    page: queryStateRoles.page,
    offset: queryStateRoles.offset,
  })
  const roleNames = rolesResponse?.data !== 'Forbidden resource' ? rolesResponse?.data?.map((r) => r.roleName) : []

  const [updatePasswordUser] = useUpdatePasswordUserMutation()
  const [createUser] = useCreateUserMutation()
  const [updateUser] = useUpdateUserMutation()
  const [deleteUser] = useDeleteUserMutation()

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = e.target
    const data = {
      emailClient: emailClient,
      email: formData.email.value,
      password: formData.password.value,
      confirmPassword: formData.confirmPassword.value,
      roles,
      hasApiKey: false,
    }
    try {
      await createUser({ body: data }).unwrap()
      setPage(0)
      handleClose()
      refetch()
      toaster.success(`${t('common:createUser')} ${t('common:success')}`)
    } catch (error) {
      toaster.error(`${t('common:createUser')} ${t('common:error')}`)
      console.error(error?.data?.data)
    }
  }
  const handleReset = async (e) => {
    e.preventDefault()
    const data = {
      email: userToReset,
      password,
      confirmNewPassword: confirmPassword,
    }
    try {
      await updatePasswordUser({ body: data }).unwrap()
      handleCloseReset()
      refetch()
      toaster.success(t('messages:resetPasswordSuccess'))
    } catch (error) {
      toaster.error(t('messages:resetPasswordError'))
      console.error(error?.data?.data)
    }
  }
  const handleUpdate = async (e, row) => {
    e.preventDefault()

    const data = row ? { status: row.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' } : { roles }
    const email = row ? row.email : activeUser.email

    try {
      await updateUser({ body: data, email }).unwrap()
      handleClose()
      refetch()
      toaster.success(t('messages:updateSuccess'))
    } catch (error) {
      toaster.error(t('messages:updateError'))
      console.error(error?.data?.data || error)
    }
  }
  const handleDelete = async (e) => {
    e.preventDefault()
    try {
      await deleteUser({ email: activeUser.id }).unwrap
      toaster.success(t('messages:deletionSuccess'))
      handleCloseDelete()
      refetch()
    } catch (error) {
      toaster.error(t('messages:deletionError'))
      console.error(error?.data?.data || error)
    }
  }

  const handleChangePage = (_, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    setRowsPerPage(newRowsPerPage)
    saveConfig({ rowsPerPage: newRowsPerPage })
  }
  const orderedColumns = useMemo(
    () => getOrderedColumns(config.columnOrder, columns),
    [JSON.stringify(config.columnOrder), columns],
  )
  const handleColumnOrderChange = (params) => {
    const updatedOrder = updateListOrder(config.columnOrder, params)
    if (JSON.stringify(config.columnOrder) !== JSON.stringify(updatedOrder)) {
      saveConfig({ columnOrder: updatedOrder })
    }
  }
  const handleColumnVisibilityModelChange = (newModel) => {
    saveConfig({ visibleColumns: newModel })
  }
  const visibleColumnsModel = useMemo(
    () => getVisibilityModel(config.visibleColumns, columns),
    [config.visibleColumns, columns],
  )

  const handleClose = () => {
    setOpen(false)
    setEdit(false)
    setRoles([])
    setActiveUser({ id: null, email: '', status: '' })
    setErrorMessage('')
    setError(false)
  }
  const handleClose2 = () => {
    // setIsLoading(false)
    setOpen2(false)
    setMessage('')
    setIdToDelete('')
  }
  const handleCloseReset = () => {
    setOpenReset(false)
    setErrorMessage('')
    setError(false)
  }
  const handleCloseDelete = () => {
    setOpenDelete(false)
    setEdit(false)
    setActiveUser({ id: null, email: '', status: '' })
    setErrorMessage('')
    setError(false)
  }

  const handleDispatch = () => {
    return dispatch(
      updateUsersAdminFilters({
        email: userEmail,
        roles: roles,
        page,
        viewMode: true,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
      }),
    )
  }
  const handleResetButton = () => {
    if (userEmail !== '') setUserEmail('')
    if (roles && roles.length !== 0) setRoles([])
    setPage(0)

    dispatch(
      updateUsersAdminFilters({
        email: '',
        status: '',
        roles: [],
        page: 0,
        viewMode: true,
        limit: 50,
        offset: 0,
      }),
    )
  }

  useEffect(() => {
    if (!edit && (password !== '' || confirmPassword !== '' || email !== '' || activeUser.email !== '')) {
      if (!regexEmail.test(email) && open) {
        setErrorMessage('Invalid email value')
        setError(true)
      } else if (!regexEmail.test(userToReset) && openReset) {
        setErrorMessage('Invalid email value')
        setError(true)
      } else if (!regex.test(password) && password !== confirmPassword) {
        setErrorMessage(`${t('messages:passwordTooWeak')}. ${t('messages:passwordDontMatch')}`)
        setError(true)
      } else if (!regex.test(password)) {
        setErrorMessage(t('messages:passwordTooWeak'))
        setError(true)
      } else if (password !== confirmPassword) {
        setErrorMessage(t('messages:passwordDontMatch'))
        setError(true)
      } else {
        setError(false)
        setErrorMessage('')
      }
    }
  }, [password, confirmPassword, email, userToReset])

  useEffect(() => {
    handleDispatch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page])

  return (
    <Box>
      <Box className={`${isLoadingRoles && isLoadingUsersAdmin && 'spinner-transition '} filter`}>
        <Box className="component-title-wrapper">
          <Box className="component-title">
            <Typography variant="h2">{t('common:dashboardUsers')}</Typography>
            <Box className="user-icons">
              {activeServices.includes('reportsitem') && (
                <Button
                  variant="outlined"
                  disabled={!activeServices.includes('reportsitem')}
                  onClick={() => setShowDownloadModal(true)}
                  endIcon={<IosShareOutlinedIcon sx={{ fill: 'var(--lime-04)' }} />}
                >
                  <Typography variant="title2">{t('common:exportList')}</Typography>
                </Button>
              )}
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  setOpen(true)
                }}
              >
                {`${t('common:createNew')} ${t('common:user')}`}
              </Button>
            </Box>
          </Box>
        </Box>

        <Paper elevation={0}>
          <Box style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
            <Box className="filter-bar">
              <Button variant="label" endIcon={<MenuOutlinedIcon />} onClick={() => setModalOpen(true)}>
                {t('common:filters')}
              </Button>
              <TablePagination
                labelRowsPerPage={[]}
                component="div"
                count={pagination?.totalElements || 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={config.rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton
                showLastButton
              />
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Tooltip title={t('common:showHideColumns')} arrow>
                  <Button
                    variant="text"
                    aria-label={t('common:showHideColumns')}
                    onClick={() => apiRef.current.showPreferences('columns')}
                    sx={{
                      padding: 2,
                      justifyContent: 'center',
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                  >
                    <Visibility fontSize="medium" sx={{ color: '#00C43D' }} />
                  </Button>
                </Tooltip>
                <IconButton
                  onClick={refetch}
                  disabled={isLoadingUsersAdmin && isLoadingRoles}
                  title={t('common:refresh')}
                >
                  <RefreshIcon color="action" />
                </IconButton>
              </Box>
            </Box>

            <DataGridPro
              getRowHeight={() => 'auto'}
              getRowId={(row) => row._id || row.id}
              rows={usersAdmin || []}
              columns={orderedColumns}
              apiRef={apiRef}
              slots={{
                columnMenu: CustomColumnMenu,
                loadingOverlay: CustomLoadingOverlay,
              }}
              hideFooter={true}
              autoHeight
              sortModel={config.sortModel}
              onSortModelChange={(newSortModel) => saveConfig({ sortModel: newSortModel })}
              columnOrder={config.columnOrder}
              onColumnOrderChange={handleColumnOrderChange}
              pinnedColumns={config.pinnedColumns}
              onPinnedColumnsChange={(newPinnedColumns) => saveConfig({ pinnedColumns: newPinnedColumns })}
              columnVisibilityModel={visibleColumnsModel}
              onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
              loading={
                isLoadingRoles || isLoadingUsersAdmin || configLoading || isFetchingUsersAdmin || isFetchingRoles
              }
              sx={{
                height: 'calc(100vh - 19.5rem)',
                '& .MuiDataGrid-virtualScroller': {
                  overflow: 'auto',
                },
              }}
            />
          </Box>
        </Paper>

        <FilterModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          handleResetButton={handleResetButton}
          setPage={setPage}
          handleDispatch={handleDispatch}
        >
          <Box className="modal-box-filters">
            <Box className="modal-box">
              <InputLabel htmlFor="userStatus">{`${t('common:by')} ${t('common:email')}`}</InputLabel>
              <TextField
                size="small"
                value={userEmail}
                onChange={(event) => setUserEmail(event.target.value)}
                placeholder={`${t('common:insert')} ${t('common:email')}`}
              />
            </Box>
            <Box className="modal-box">
              <InputLabel htmlFor="roles">
                {t('common:by')} {t('common:role')}
              </InputLabel>
              <Select
                required
                fullWidth
                size="small"
                multiple
                value={roles}
                onChange={(e) =>
                  setRoles(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)
                }
                renderValue={(selected) => selected.join(', ')}
              >
                {roleNames?.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={roles.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        </FilterModal>

        <Modal open={open} onClose={handleClose}>
          <Box className="modal">
            <Box className="modal-top">
              <Box className="modal-titles">
                <Typography variant="title" component="h2">
                  {edit ? `${t('common:edit')} ${t('common:user')}` : `${t('common:create')} ${t('common:user')}`}
                </Typography>
              </Box>
              <Fab
                variant="close"
                onClick={() => {
                  handleClose()
                }}
              >
                <CloseOutlined />
              </Fab>
            </Box>
            <Box component="form" onSubmit={(e) => (edit ? handleUpdate(e) : handleSubmit(e))}>
              <Box gap="1rem" flexDirection="column" display="flex">
                {edit && (
                  <Box className="modal-box">
                    <InputLabel>{t('common:email')}</InputLabel>
                    <Typography variant="title3">{activeUser.email}</Typography>
                  </Box>
                )}
                {!edit && (
                  <Box className="user-box">
                    <InputLabel htmlFor="email">{t('common:email')}</InputLabel>
                    <TextField
                      size="small"
                      placeholder={`${t('common:insert')} ${t('common:email')}`}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      id="email"
                    ></TextField>
                  </Box>
                )}

                {!edit && (
                  <Box className="user-box">
                    <InputLabel htmlFor="password">{t('common:password')}</InputLabel>
                    <TextField
                      size="small"
                      placeholder={`${t('common:insert')} ${t('common:password')}`}
                      type="password"
                      required
                      id="password"
                      onChange={(e) => {
                        setPassword(e.target.value)
                      }}
                    ></TextField>
                  </Box>
                )}

                {!edit && (
                  <Box className="modal-box">
                    <InputLabel htmlFor="confirmPassword">{t('messages:confirmPassword')}</InputLabel>
                    <TextField
                      size="small"
                      placeholder={t('messages:confirmPassword')}
                      type="password"
                      required
                      id="confirmPassword"
                      onChange={(e) => {
                        setConfirmPassword(e.target.value)
                      }}
                    ></TextField>
                  </Box>
                )}

                <Box className="modal-box">
                  <InputLabel htmlFor="roles">{t('common:roles')}</InputLabel>
                  <Select
                    required
                    id="roles"
                    size="small"
                    multiple
                    displayEmpty
                    value={roles}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <Typography variant="subtitle3">{`${t('common:select')} ${t('common:roles')}`}</Typography>
                        ) // This can be a translated string or any placeholder
                      }
                      return selected.join(', ')
                    }}
                    onChange={(e) =>
                      setRoles(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)
                    }
                  >
                    {roleNames?.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={roles.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>
              <Box className="modal-filter-buttons">
                {errorMessage ? (
                  <Button size="small" variant="outlined" color="error">
                    {errorMessage}
                  </Button>
                ) : (
                  ''
                )}
                <Button disabled={error} size="small" variant="contained" type="submit">
                  {edit ? t('common:save') : `${t('common:createNew')} ${t('common:user')}`}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>

        <Modal open={openReset} onClose={handleCloseReset}>
          <Box className="modal">
            <Box className="modal-top">
              <Box className="modal-titles">
                <Typography variant="title">{t('messages:changePassword')}</Typography>
              </Box>
              <Fab variant="close" onClick={() => handleCloseReset()} aria-label="sidebar-collapse">
                <CloseOutlinedIcon />
              </Fab>
            </Box>

            <Box className="modal-box-filters">
              <Box className="modal-box">
                <InputLabel>{t('common:email')}</InputLabel>
                <Typography variant="title3">{userToReset}</Typography>
              </Box>

              {!edit && (
                <Box className="modal-box">
                  <InputLabel htmlFor="password">{t('messages:newPassword')}</InputLabel>
                  <TextField
                    size="small"
                    type="password"
                    helperText={errorMessage.length > 0 ? errorMessage : null}
                    error={Boolean(errorMessage)}
                    placeholder={`${t('common:type')} ${t('common:here').toLowerCase()}...`}
                    required
                    id="password"
                    onChange={(e) => {
                      setPassword(e.target.value)
                    }}
                  ></TextField>
                </Box>
              )}
              {!edit && (
                <Box className="modal-box">
                  <InputLabel htmlFor="confirmPassword">{t('messages:confirmNewPassword')}</InputLabel>
                  <TextField
                    size="small"
                    type="password"
                    helperText={errorMessage.length > 0 ? errorMessage : null}
                    error={Boolean(errorMessage)}
                    placeholder={`${t('common:type')} ${t('common:here').toLowerCase()}...`}
                    required
                    id="confirmPassword"
                    onChange={(e) => {
                      setConfirmPassword(e.target.value)
                    }}
                  ></TextField>
                </Box>
              )}
            </Box>
            <Box className="modal-filter-buttons">
              <Button
                disabled={error}
                size="small"
                variant="contained"
                type="submit"
                onClick={(e) => {
                  return handleReset(e)
                }}
              >
                {t('common:save')}
              </Button>
            </Box>
          </Box>
        </Modal>

        <Modal open={openDelete} onClose={handleCloseDelete}>
          <Box className="modal">
            <Box className="modal-top">
              <Box className="modal-titles">
                <Typography variant="title">{t('common:deleteUser')}</Typography>
              </Box>
              <Fab variant="close" onClick={() => handleCloseDelete()} aria-label="sidebar-collapse">
                <CloseOutlinedIcon />
              </Fab>
            </Box>

            <Box className="user-text-delete">
              <p>{t('messages:deleteUserSelected')}</p>
              <Box>
                <Typography variant="title">{activeUser?.email}</Typography>?
              </Box>
              <p>{t('messages:actionIrreversible')}</p>
            </Box>
            {errorMessage ? (
              <Button size="small" variant="outlined" color="error">
                {errorMessage}
              </Button>
            ) : (
              ''
            )}
            <Box className="modal-filter-buttons">
              <Button variant="outlined" onClick={() => handleCloseDelete()}>
                {t('common:no')}
              </Button>
              <Button variant="contained" type="submit" onClick={(e) => handleDelete(e)}>
                {t('common:yes')}
              </Button>
            </Box>
          </Box>
        </Modal>

        <Modal open={open2} onClose={handleClose2}>
          <Box className="create-role">
            <Typography id="modal-modal-title" variant="h6" component="h2" style={{ marginBottom: '2rem' }}>
              {t('common:deleteUser')}
            </Typography>
            <Box
              className="form"
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '2.5rem',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {message && <Box>{message}</Box>}
              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1rem' }}>
                <Button className="" size="large" variant="outlined" onClick={() => handleDelete(idToDelete)}>
                  {t('common:yes')}
                </Button>
                <Button className="" size="large" variant="outlined" onClick={handleClose2}>
                  {t('common:no')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
        <Modal open={showDownloadModal} onClose={() => setShowDownloadModal(false)}>
          <div>
            <DataExportModal
              show={showDownloadModal}
              onClose={() => setShowDownloadModal(false)}
              data={usersAdmin}
              dataMapper={userAdminDataMapper}
              dataMapperForPDF={userAdminDataMapperForPDF}
              fileName={'dashboard-users'}
              typeDataExport={'users'}
            />
          </div>
        </Modal>
      </Box>
    </Box>
  )
}

export default Users
