import './generalAlerts.scss'
import { Box, Button, Fab, InputLabel, Link, MenuItem, Modal, Select, TableContainer, Typography } from '@mui/material'
import { buildTimeStringFromTimestamp } from '../../../components/common/time/timeHelper'
import { useEffect, useMemo, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { TableChip } from '../../../components/utilities/TableChip'
import { DataGridComponent } from '../../../components/common/datagrid/DataGridComponent'
import {
  useFindGeneralAlertsQuery,
  useUpdateAlertStatusMutation,
} from '../../../features/generalAlerts/generalAlertsSlice'
import toaster from '../../../toaster'

const alertStatusValues = [
  'PENDING',
  'REVIEW_IN_PROGRESS',
  'REVIEW_FINISHED',
  'CLOSED_WITH_ACTIONS',
  'CLOSED_WITHOUT_ACTIONS',
]

const GeneralAlertsDatatable = ({
  setIsLoading,
  setCount,
  withPagination,
  hasPermissions,
  type,
  hideActions,
  rows,
  setModalOpen,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  hasTabs,
  isFromDetail,
}) => {
  const { emailClient } = useSelector((state) => state.login)
  const queryState = useSelector((state) => state.generalAlerts.filters)
  const { fromDate, toDate, id } = queryState
  const navigate = useNavigate()
  const { t } = useTranslation(['common', 'messages'])
  const [internalType, setInternalType] = useState('')
  const [openUpdateAlert, setOpenUpdateAlert] = useState(false)
  const [status, setStatus] = useState('')
  const [alertToUpdate, setAlertToUpdate] = useState()
  const location = useLocation()
  const offset = rowsPerPage * page

  const {
    data,
    isLoading,
    refetch,
    isFetching: isFetchingGeneralAlert,
  } = useFindGeneralAlertsQuery({
    emailClient,
    id,
    limit: rowsPerPage,
    offset,
    page,
    fromDate,
    toDate,
    type,
  })

  const { generalAlertsTemp, pagination } = useMemo(
    () => ({ generalAlertsTemp: data?.data, pagination: data?.pagination }),
    [data],
  )

  const generalAlerts = rows ? [...rows] : Array.isArray(generalAlertsTemp) ? [...generalAlertsTemp] : []

  const [updateAlertStatus] = useUpdateAlertStatusMutation()

  const handleAlertStatusChange = async () => {
    try {
      await updateAlertStatus({ alertToUpdate: alertToUpdate, status }).unwrap()
      refetch()
      setOpenUpdateAlert(!openUpdateAlert)
      toaster.success(t('messages:updateSuccess'))
    } catch (error) {
      toaster.error(t('messages:updateError'))
      console.error(error?.data?.data)
    }
  }

  useEffect(() => {
    setInternalType(type)
  }, [isLoading])

  useEffect(() => {
    if (pagination && withPagination) {
      setCount(pagination?.totalElements)
    }
  }, [pagination, setCount, withPagination])

  const columnsAml = useMemo(
    () => [
      {
        field: 'id',
        headerName: t('common:alertId'),
        minWidth: 220,
        renderCell: (params) => <Box className="cellOverflow">{params.row.id}</Box>,
      },
      {
        field: 'updatedAt',
        headerName: t('common:updatedAt'),
        minWidth: 180,
      },
      {
        field: 'userId',
        headerName: t('common:userId'),
        minWidth: 220,
        hide: hideActions,
        renderCell: (params) => <Box className="cellOverflow">{params.row.details.consumerUserId}</Box>,
      },
      {
        field: 'name',
        headerName: t('common:name'),
        minWidth: 210,
        renderCell: (params) => <Box className="cellOverflow">{params.row.details.name}</Box>,
      },
      {
        field: 'countries',
        headerName: t('common:countries'),
        minWidth: 150,
        renderCell: (params) => <Box className="cellOverflow">{params.row.details.countries}</Box>,
      },
      {
        field: 'types',
        headerName: t('common:types'),
        minWidth: 150,
        renderCell: (params) => <Box className="cellOverflow">{params.row.details.types?.join(', ')}</Box>,
      },
      {
        field: 'details',
        headerName: t('common:details'),
        minWidth: 210,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <Link
              onClick={() => window.open(params.row.details.shareUrl, '_blank', 'rel=noopener noreferrer')}
              disabled={!(params.row.details && params.row.details.shareUrl)}
            >
              {t('common:viewAmlDetails')}
            </Link>
          )
        },
      },
      {
        field: 'status',
        headerName: t('common:status'),
        minWidth: 120,
        renderCell: (params) => <Box className="cellOverflow">{params.row.status ?? 'PENDING'}</Box>,
      },
      {
        field: 'update',
        headerName: t('common:updateStatus'),
        minWidth: 140,
        renderCell: (params) => {
          return (
            <Link
              onClick={() => {
                setOpenUpdateAlert(true)
                setAlertToUpdate(params.row.id)
              }}
            >
              {t('common:updateAlert')}
            </Link>
          )
        },
      },
    ],
    [],
  )

  const columnsAmlCrypto = useMemo(
    () => [
      {
        field: 'id',
        headerName: t('common:alertId'),
        minWidth: 220,
        renderCell: (params) => <Typography variant="number">{params.row.id}</Typography>,
      },
      {
        field: 'updatedAt',
        headerName: t('common:updatedAt'),
        minWidth: 180,
        renderCell: (params) => {
          return <Typography variant="number">{buildTimeStringFromTimestamp(params.row.updatedAt)}</Typography>
        },
      },
      {
        field: 'transactionId',
        headerName: t('common:transactionId'),
        minWidth: 300,
        hide: hideActions,
        renderCell: (params) => <Typography variant="number">{params.row.details.transactionId ?? '---'}</Typography>,
      },
      {
        field: 'protocol',
        headerName: t('common:protocol'),
        minWidth: 120,
        renderCell: (params) => <Box>{params.row.details.protocol ?? '---'}</Box>,
      },
      {
        field: 'address',
        headerName: t('common:address'),
        minWidth: 120,
        renderCell: (params) => <Box>{params.row.details.address ?? '---'}</Box>,
      },
      {
        field: 'score',
        headerName: t('common:score'),
        minWidth: 120,
        renderCell: (params) => <Box>{params.row.details.score ?? '---'}</Box>,
      },
      {
        field: 'level',
        headerName: t('common:level'),
        minWidth: 120,
        renderCell: (params) => <Box>{params.row.details.level ?? '---'}</Box>,
      },
      {
        field: 'category',
        headerName: t('common:category'),
        minWidth: 120,
        renderCell: (params) => {
          return <Box>{params.row.details.category ? params.row.details.category?.join(', ') : '---'}</Box>
        },
      },

      {
        field: 'action',
        headerName: t('common:action'),
        hide: hideActions || (location?.pathname?.includes('case-management') && !hasPermissions),
        minWidth: 200,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <Link onClick={() => navigate(`/transactions/${params.row.details.transactionId}`)}>
              {t('common:viewTxsDetails')}
            </Link>
          )
        },
      },
      {
        field: 'status',
        headerName: t('common:status'),
        minWidth: 120,
        renderCell: (params) => <TableChip noIcon={true} action={params.row.status ?? 'PENDING'} />,
      },
      {
        field: 'update',
        headerName: t('common:updateStatus'),
        minWidth: 140,
        renderCell: (params) => {
          return (
            <Button
              variant="outlinedChip"
              onClick={() => {
                setOpenUpdateAlert(true)
                setAlertToUpdate(params.row.id)
              }}
            >
              {t('common:update')}
            </Button>
          )
        },
      },
    ],
    [],
  )

  const columnsTransactions = useMemo(
    () => [
      {
        field: 'id',
        headerName: t('common:alertId'),
        minWidth: 220,
        renderCell: (params) => <Typography variant="number">{params.row.id}</Typography>,
      },
      {
        field: 'updatedAt',
        headerName: t('common:updatedAt'),
        minWidth: 180,
        renderCell: (params) => {
          return <Typography variant="number">{buildTimeStringFromTimestamp(params.row.updatedAt)}</Typography>
        },
      },
      {
        hide: hideActions,
        field: 'transactionId',
        headerName: t('common:transactionId'),
        minWidth: 300,
        renderCell: (params) => <Typography variant="number">{params.row.details.transactionId ?? '---'}</Typography>,
      },
      {
        field: 'ruleId',
        headerName: t('common:ruleId'),
        minWidth: 160,
        renderCell: (params) => <Typography variant="number">{params.row.details.ruleId ?? '---'}</Typography>,
      },
      {
        field: 'ruleInstanceId',
        headerName: t('common:ruleInstanceId'),
        minWidth: 180,
        renderCell: (params) => <Typography variant="number">{params.row.details.ruleInstanceId ?? '---'}</Typography>,
      },
      {
        field: 'ruleName',
        headerName: t('common:ruleName'),
        minWidth: 120,
        renderCell: (params) => {
          return <Box>{params.row.details.ruleName ?? '---'}</Box>
        },
      },
      {
        field: 'alertDetails',
        headerName: t('common:alertDetails'),
        minWidth: 180,
        height: 36,
        renderCell: (params) => {
          return (
            <Box
              sx={{
                height: '100%',
                maxWidth: 250,
                maxHeight: 60,
                overflowX: 'auto',
                overflowY: 'auto',
                whiteSpace: 'normal',
                display: 'flex',
                alignItems:
                  params.row.details.alertDetails && params.row.details.alertDetails.length > 60 ? 'start' : 'center',
              }}
            >
              {params.row.details.alertDetails ?? '---'}
            </Box>
          )
        },
      },
      {
        field: 'ruleAction',
        headerName: t('common:ruleAction'),
        minWidth: 150,
        renderCell: (params) => {
          return (
            <Box className="table-body-cell">
              {params.row.details.ruleAction ? <TableChip action={params.row.details.ruleAction} /> : '---'}
            </Box>
          )
        },
      },
      {
        field: 'status',
        headerName: t('common:status'),
        minWidth: 120,
        renderCell: (params) => <TableChip noIcon={true} action={params.row.status ?? 'PENDING'} />,
      },
      {
        field: 'action',
        headerName: t('common:action'),
        hide: hideActions || (location?.pathname?.includes('case-management') && !hasPermissions),
        minWidth: 200,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <Link onClick={() => navigate(`/transactions/${params.row.details.transactionId}`)}>
              {t('common:viewTxsDetails')}
            </Link>
          )
        },
      },
      {
        field: 'update',
        headerName: t('common:updateStatus'),
        minWidth: 140,
        renderCell: (params) => {
          return (
            <Button
              variant="outlinedChip"
              onClick={() => {
                setOpenUpdateAlert(true)
                setAlertToUpdate(params.row.id)
              }}
            >
              {t('common:update')}
            </Button>
          )
        },
      },
    ],
    [],
  )

  return (
    <Box>
      {isFromDetail ? (
        <TableContainer>
          <DataGrid
            getRowId={(row) => row._id || row.id}
            getRowHeight={() => 'auto'}
            rows={
              generalAlerts && Array.isArray(generalAlerts.data) && generalAlerts.data.length > 0
                ? generalAlerts.data
                : generalAlerts && Array.isArray(generalAlerts) && generalAlerts.length > 0
                ? generalAlerts
                : []
            }
            columns={
              internalType === 'transactionRule'
                ? columnsTransactions
                : internalType === 'aml'
                ? columnsAml
                : columnsAmlCrypto
            }
            hideFooter={true}
            autoHeight={true}
            disableColumnResize={true}
          />
        </TableContainer>
      ) : (
        <DataGridComponent
          rows={
            generalAlerts && Array.isArray(generalAlerts.data) && generalAlerts.data.length > 0
              ? generalAlerts.data
              : generalAlerts && Array.isArray(generalAlerts) && generalAlerts.length > 0
              ? generalAlerts
              : []
          }
          columns={
            internalType === 'transactionRule'
              ? columnsTransactions
              : internalType === 'aml'
              ? columnsAml
              : columnsAmlCrypto
          }
          count={pagination?.totalElements || 0}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          setIsLoading={setIsLoading}
          setModalOpen={setModalOpen}
          hasTabs={hasTabs}
          disableColumnResize={true}
          isLoading={isLoading || isFetchingGeneralAlert}
          tableKey="generalAlerts"
        />
      )}

      <Modal
        open={openUpdateAlert}
        onClose={() => {
          setOpenUpdateAlert(!openUpdateAlert)
        }}
      >
        <Box className="modal">
          <Box className="modal-top">
            <Box className="modal-titles">
              <Typography variant="title">{t('common:updateAlert')}</Typography>
            </Box>
            <Fab variant="close" onClick={() => setOpenUpdateAlert(false)}>
              <CloseOutlinedIcon />
            </Fab>
          </Box>

          <Box className="modal-box-filters">
            <Box className="modal-box">
              <Typography variant="subtitle3">
                {t('messages:doYouWantUpdateAlert')} {alertToUpdate}?
              </Typography>
            </Box>
          </Box>

          <Box className="modal-box">
            <InputLabel>{t('common:newStatus')}:</InputLabel>
            <Select
              fullWidth
              size="small"
              value={status}
              onChange={(e) => {
                if (e.target.value) {
                  setStatus(e.target.value)
                } else setStatus('')
              }}
              displayEmpty
            >
              <MenuItem disabled value="">
                <Typography variant="subtitle3">
                  {t('common:select')} {t('common:newStatus')}
                </Typography>
              </MenuItem>
              {alertStatusValues?.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box className="modal-filter-buttons">
            <Button variant="outlinedGrey" onClick={() => setOpenUpdateAlert(!openUpdateAlert)}>
              {t('common:close')}
            </Button>
            <Button disabled={status === ''} variant="contained" onClick={handleAlertStatusChange}>
              {t('common:save')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default GeneralAlertsDatatable
