import { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { useTranslation } from 'react-i18next'
import { CATEGORIES_KEYS } from '../../admin/riskMatrix/constants'
import RiskMatrixResultItem from './RiskMatrixResultItem'
import RiskMatrixResultGroup from './RiskMatrixResultGroup'

const RiskMatrixResult = ({ riskResult, country = 'BR' }) => {
  const showDataDetail = false
  const groupByCategories = true
  const { t } = useTranslation(['common', 'messages'])

  const results = useMemo(() => {
    if (!groupByCategories) {
      return riskResult.results || []
    }

    return CATEGORIES_KEYS.map((category) => {
      let rules = riskResult?.results.filter((result) => result.category === category)
      const rulesHit = rules.filter((result) => !!result.isHit)
      const rulesNoHit = rules.filter((result) => !result.isHit)

      let ruleToShow = rulesHit.sort((a, b) => b.score - a.score).find(Boolean)
      const dataToEvaluate = rulesNoHit.flatMap(({ dataToEvaluate }) => dataToEvaluate).filter(Boolean)

      if (!ruleToShow) {
        ruleToShow = {
          attribute: '',
          operator: '',
          dataToEvaluate: [...new Set(dataToEvaluate)].join(', '),
          value: '',
          operatorFound: true,
          isHit: false,
          score: 0,
        }
      }

      return {
        category,
        grouped: true,
        ...ruleToShow,
      }
    })
  }, [groupByCategories, riskResult.results])

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column',
        width: '80vw',
        height: '70vh',
        overflowY: 'scroll',
        overflowX: 'hidden',
      }}
    >
      {/* <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <FormControlLabel
          control={<Checkbox checked={groupByCategories} />}
          label={t('messages:riskmatrix.results.groupByCategory')}
          onChange={() => setGroupByCategories((prev) => !prev)}
        />
      </Box> */}
      {results && (
        <Box>
          <Grid container rowSpacing={1} columnSpacing={2} minHeight={50} alignContent="center">
            <Grid xs={1.5}>
              <Typography fontWeight="bold">{t('messages:riskmatrix.title.category')}</Typography>
            </Grid>
            <Grid xs={3}>
              <Typography fontWeight="bold">{t('messages:riskmatrix.title.attribute')}</Typography>
            </Grid>
            <Grid xs={2}>
              <Typography fontWeight="bold">{t('messages:riskmatrix.title.valueFound')}</Typography>
            </Grid>
            <Grid xs={1.5}>
              <Typography fontWeight="bold">{t('messages:riskmatrix.title.operator')}</Typography>
            </Grid>
            <Grid xs={2}>
              <Typography fontWeight="bold">{t('messages:riskmatrix.title.value')}</Typography>
            </Grid>
            <Grid xs={1} textAlign="center">
              <Typography fontWeight="bold">{t('messages:riskmatrix.title.isHit')}</Typography>
            </Grid>
            <Grid xs={1} textAlign="center">
              <Typography fontWeight="bold">{t('messages:riskmatrix.title.score')}</Typography>
            </Grid>
          </Grid>
          {results.map((resultItem) => {
            const { category, attribute, operator, value, groupResults } = resultItem
            const key = `${category}-${attribute}-${operator}-${
              typeof value === 'string' ? value : JSON.stringify(value)
            }`

            if (Array.isArray(groupResults)) {
              return <RiskMatrixResultGroup key={key} {...resultItem} country={country} groupByCategories />
            }

            return <RiskMatrixResultItem key={key} {...resultItem} country={country} groupByCategories />
          })}
        </Box>
      )}

      {showDataDetail && riskResult?.data && (
        <Box>
          <Typography variant="h4">KYB data evaluated</Typography>
          {Object.keys(riskResult?.data).map((key) => {
            const data = riskResult?.data?.[key]
            if (key === 'categories') {
              return (
                <Box key={key} sx={{ marginTop: '0.5rem' }}>
                  <Box>
                    <Typography>{t(`common:${key}`)}</Typography>
                  </Box>
                  <Box>
                    {data?.map((item) => (
                      <Typography key={`${item.secao} ${item.descricao}`}>
                        {item.secao} {item.descricao} ({item.classe})
                      </Typography>
                    ))}
                  </Box>
                </Box>
              )
            }

            if (key === 'relationships') {
              return (
                <Box key={key} sx={{ marginTop: '0.5rem' }}>
                  <Box>
                    <Typography>{t(`common:${key}`)}</Typography>
                  </Box>
                  <Box>
                    {data?.map((item) => (
                      <Typography key={`${item.type} ${item.documentNumber}`}>
                        {item.type} {item.documentNumber} {item.name}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              )
            }

            let valueToShow = ''

            if (key === 'porte') {
              valueToShow = `${data?.id} ${data?.descricao}`
            } else if (key === 'addresses') {
              valueToShow = data
                ?.map(
                  (item) =>
                    `${item.street} ${item.number} ${item.apt_suite}, ${item.postcode}, ${item.city}, ${item.state}, ${item.country}`,
                )
                .join(' | ')
            } else if (key === 'naturezaJuridica') {
              valueToShow = `${data?.codigo} ${data?.descricao}`
            } else if (key === 'kyc') {
              valueToShow = data?.informação
            } else if (key === 'lastBeneficiaries') {
              valueToShow = data?.join(', ')
            } else if (typeof data === 'string') {
              valueToShow = data
            }

            return (
              <Box key={key} sx={{ marginTop: '0.5rem' }}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Typography>{t(`common:${key}`)}: </Typography>
                  <Typography>{valueToShow || 'No data found'}</Typography>
                </Box>
              </Box>
            )
          })}
        </Box>
      )}
    </Box>
  )
}

export default RiskMatrixResult
