import '../../kyt/user/user.scss'
import './case.scss'
import { useParams } from 'react-router-dom'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Spinner from '../../../components/common/spinner/spinner'
import {
  Box,
  Button,
  InputLabel,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  Link,
  Paper,
  Fab,
  Chip,
  Tab,
  MenuItem,
  Select,
  CircularProgress,
} from '@mui/material'
import { renderNames } from '../../../components/common/toRender/renderNames'
import { useSelector } from 'react-redux'
import { Link as ReactRouterLink } from 'react-router-dom'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined'
import RedoOutlinedIcon from '@mui/icons-material/RedoOutlined'
import AlertFetchSpinner from '../../../components/common/alertFetchSpinner/alertFetchSpinner'
import { buildTimeStringFromTimestamp } from '../../../components/common/time/timeHelper'
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined'
import { TableChip } from '../../../components/utilities/TableChip'
import { useGetCaseAdminPanelQuery, useGetCaseByIdQuery } from '../../../features/cases/caseSlice'
import { useFindWorkflowsQuery } from '../../../features/workflows/workflowsSlice'
import { useGetKytUserByIdQuery } from '../../../features/transactions/transactionSlice'
import { useTranslation } from 'react-i18next'
import ProcessingSpinner from '../../../components/common/alertFetchSpinner/processingSpinner'
import { CaseStatus } from '../case.status'
import { StatusIndicator } from '../../../components/utilities/StatusIndicator'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { FileUploader } from 'react-drag-drop-files'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import CustomNoRowsOverlay from '../../../components/common/datagrid/CustomNoRowsOverlay'
import ModalExportReport from './components/ModalExportReport'
import DatatableAssignRoles from '../../../components/common/datatable/DatatableAssignRoles'
import { useRolesByEmailClient } from '../../pages-hooks/useRoles'
import toaster from '../../../toaster'
import SectionCreateReport from './components/SectionCreateReport'
import TabTransactions from './components/TabTransactions'
import TabTransactionsAlerts from './components/TabTransactionsAlerts'
import TabUserCases from './components/TabUserCases'
import ModalOpenCase from './components/ModalOpenCase'
import ModalCreateComment from './components/ModalCreateComment'
import ModalScaleCase from './components/ModalScaleCase'
import ModalUpdateCase from './components/ModalUpdateCase'
import ModalCloseCase from './components/ModalCloseCase'
import TabChecklists from './components/TabChecklists'
import TabReports from './components/TabReports'
import TabTimeline from './components/TabTimeline'

const userStateValues = ['ACTIVE', 'BLOCKED', 'CREATED', 'DORMANT', 'SUSPENDED', 'TERMINATED', 'UNACCEPTABLE']

const Case = () => {
  const { theme } = useSelector((state) => state.theme)
  const { id } = useParams()
  const { accessToken, email, emailClient, activeServices, roles } = useSelector((state) => state.login)
  const [initialLoading, setInitialLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [openUserAction, setOpenUserAction] = useState(false)
  const [viewToast, setViewToast] = useState(false)
  const [message, setMessage] = useState('')
  const [isEditingState, setIsEditingState] = useState(false)
  const [userState, setUserState] = useState('')
  const [assignedRoles, setAssignedRoles] = useState([])
  const [isEditingAssign, setIsEditingAssign] = useState(false)
  const [errorAssign, setErrorAssign] = useState(false)
  const [isProcessingFile, setIsProcessingFile] = useState(false)
  const [selectedTab, setSelectedTab] = useState('cases')
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const [workflowModal, setWorkflowModal] = useState(false)
  const [scaleModal, setScaleModal] = useState(false)
  const [workflow, setWorkflow] = useState('')
  const [assignedWorkflow, setAssignedWorkflow] = useState('')
  const [actualStep, setActualStep] = useState(undefined)
  const [isFinalStep, setIsFinalStep] = useState(false)
  const [isInitialStep, setIsInitialStep] = useState(false)
  const [isDownscale, setIsDownscale] = useState(false)
  const [closeCaseModal, setCloseCaseModal] = useState(false)
  const [openCaseModal, setOpenCaseModal] = useState(false)
  const [caseStatusFinal, setCaseStatusFinal] = useState([])
  const [openUpdateMessage, setOpenUpdateMessage] = useState(false)
  const { t } = useTranslation(['common', 'messages'])
  const [aux, setAux] = useState(false)
  const rolesState = useSelector((state) => state.role?.roles)
  const tabList = [
    { key: 'cases', label: t('common:cases') },
    activeServices.includes('generalAlerts') && { key: 'transactionAlerts', label: t('common:transactionAlerts') },
    { key: 'transactions', label: t('common:transactions') },
    { key: 'assignedRoles', label: t('common:assignedRoles') },
    { key: 'checklists', label: t('common:checklists') },
    { key: 'reports', label: t('common:reports') },
    { key: 'comments', label: t('common:comments') },
    { key: 'documents', label: t('common:documents') },
    { key: 'timeline', label: t('common:timeline') },
  ].filter(Boolean)

  useRolesByEmailClient(emailClient, initialLoading, setInitialLoading)

  const { workflows, isLoading: isLoadingWorkflows } = useFindWorkflowsQuery(
    { emailClient, page: 0, offset: 0, limit: 50, status: 'ACTIVE' },
    {
      selectFromResult: ({ data, isFetching, isLoading }) => ({
        isLoading: isFetching || isLoading,
        workflows: data?.data || [],
      }),
    },
  )

  const { caseAdminPanel, isLoading: isLoadingCaseAdminPanel } = useGetCaseAdminPanelQuery(
    { emailClient },
    {
      selectFromResult: ({ data, isFetching, isLoading }) => ({
        isLoading: isFetching || isLoading,
        caseAdminPanel: data?.data || {},
      }),
    },
  )

  const {
    data: caseDetailData,
    isLoading: isLoadingCaseDetail,
    isFetching: isFetchingCaseDetail,
    refetch: refetchCaseDetail,
  } = useGetCaseByIdQuery({ id })
  const data = useMemo(() => caseDetailData?.data, [caseDetailData?.data])

  const caseIsClosed = useMemo(
    () => ['CLOSED_WITH_ACTIONS', 'CLOSED_WITHOUT_ACTIONS'].includes(data?.status),
    [data?.status],
  )

  const isAssigned = useMemo(() => {
    if (data?.assignedRoles) {
      setAssignedRoles(data?.assignedRoles)
      if (data?.assignedRoles?.find((as) => roles.includes(as.roleName))) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }, [data?.assignedRoles, roles])

  const reports = useMemo(() => data?.reports || [], [data?.reports])
  const lastReport = useMemo(() => {
    if (reports.length > 0) {
      const filteredReports = reports?.filter((r) => !r.closed)
      return filteredReports?.[0]
    }
  }, [reports])

  const {
    data: caseUserData,
    isLoading: isLoadingCaseUser,
    isFetching: isFetchingCaseUser,
  } = useGetKytUserByIdQuery({ emailClient, userId: data?.userId }, { skip: !data?.userId })
  const user = useMemo(() => caseUserData?.data || {}, [caseUserData?.data])

  const hasPermissions = useMemo(() => {
    if (
      Array.isArray(caseAdminPanel?.defaultAssigned) &&
      caseAdminPanel?.defaultAssigned.length > 0 &&
      caseAdminPanel?.defaultAssigned.find((d) => roles.includes(d.roleName)) !== undefined
    ) {
      return true
    }

    return false
  }, [caseAdminPanel?.defaultAssigned, roles])

  const canEditUserAction = useMemo(() => {
    if (
      (user?.type === 'consumer' && activeServices.includes('kytcreateConsumerUserEvent')) ||
      (user?.type === 'business' && activeServices.includes('kytcreateBusinessUserEvent'))
    ) {
      return true
    } else {
      return false
    }
  }, [activeServices, user?.type])

  const handleChange = useCallback(
    (event, newValue) => {
      setSelectedTab(newValue)
    },
    [setSelectedTab],
  )

  const CustomTabList = styled(TabList)({
    borderBottom: '1px solid #1C282026',
    '& ::-webkit-scrollbar-thumb': {
      background: theme === 'light' ? '#c9d2cc' : '#57635b',
    },
  })

  useEffect(() => {
    if (caseAdminPanel?.statusAccess && roles) {
      const allowedStatuses = CaseStatus.filter((status) => {
        const rolesForStatus = caseAdminPanel?.statusAccess[status]
        return (
          Array.isArray(rolesForStatus) &&
          (!rolesForStatus.length || roles.some((role) => rolesForStatus.includes(role)))
        )
      })
      setCaseStatusFinal(allowedStatuses)
    }
  }, [caseAdminPanel?.statusAccess, roles])

  useEffect(() => {
    if (data) {
      const { assignedWorkflow, actualStep } = data

      // ASSIGNED WORKFLOW
      if (assignedWorkflow && actualStep !== undefined) {
        setAssignedWorkflow(assignedWorkflow)
        setActualStep(actualStep)

        const exists = Array.isArray(workflows) ? workflows.find((w) => w.id === assignedWorkflow) : undefined

        if (exists) {
          const isFinalStep = exists.steps?.length - 1 < actualStep + 1
          const isInitialStep = actualStep === 0

          setWorkflow(exists)
          setIsInitialStep(isInitialStep ? true : false)
          setIsFinalStep(isFinalStep ? true : false)
        }
      } else {
        setAssignedWorkflow('')
        setActualStep(undefined)
      }
    }
  }, [data])

  useEffect(() => {
    if (user?.userStateDetails?.state) setUserState(user?.userStateDetails?.state)
  }, [user])

  // COMMENTS
  const postNewComment = ({ comment }) => {
    setIsLoading(true)
    if (!process.env.REACT_APP_IS_DEMO) {
      const url = `${process.env.REACT_APP_BASEURL}/caseManagement/${id}/comments`
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        method: 'POST',
        body: JSON.stringify({
          emailClient: emailClient,
          email: email,
          description: comment,
        }),
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          setModalOpen(false)
          refetchCaseDetail()
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
      setModalOpen(false)
    }
  }

  // REPORTS
  const createReport = ({ report }) => {
    setIsLoading(true)
    if (!process.env.REACT_APP_IS_DEMO) {
      const url = `${process.env.REACT_APP_BASEURL}/caseManagement/${id}/reports`
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        method: 'POST',
        body: JSON.stringify({
          emailClient: emailClient,
          email: email,
          userId: data.userId,
          description: report,
        }),
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            toaster.success(t('messages:creationSuccess'), { autoClose: 5000, closeButton: true })
          } else {
            toaster.error(res.message ? res?.message : t('messages:createError'), {
              autoClose: 5000,
              closeButton: true,
            })
          }
          refetchCaseDetail()
          setIsLoading(false)
        })
        .catch((error) => {
          console.error('[CREATE CASE REPORT ERROR]', error)
          toaster.error(error.message ? error.message : t('messages:createError'), {
            autoClose: 5000,
            closeButton: true,
          })
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
    }
  }

  const updateReport = ({ report }) => {
    setIsLoading(true)
    if (!process.env.REACT_APP_IS_DEMO) {
      const url = `${process.env.REACT_APP_BASEURL}/caseReports/${lastReport._id}`
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        method: 'PATCH',
        body: JSON.stringify({
          emailClient: emailClient,
          email: email,
          userId: data.userId,
          description: report,
        }),
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            toaster.success(t('messages:updateSuccess'), { autoClose: 5000, closeButton: true })
          } else {
            toaster.error(res.message ? res?.message : t('messages:updateError'), {
              autoClose: 5000,
              closeButton: true,
            })
          }
          setErrorAssign(false)
          refetchCaseDetail()
          setIsLoading(false)
        })
        .catch((error) => {
          console.error('[UPDATE CASE REPORT ERROR]', error)
          toaster.error(error.message ? error.message : t('messages:updateError'), {
            autoClose: 5000,
            closeButton: true,
          })
          setIsLoading(false)
        })
    } else {
      toaster.success(t('messages:updateSuccess'), { autoClose: 5000, closeButton: true })
      setIsLoading(false)
    }
  }

  const closeReport = ({ report }) => {
    setIsLoading(true)
    if (!process.env.REACT_APP_IS_DEMO) {
      const url = `${process.env.REACT_APP_BASEURL}/caseManagement/${lastReport._id}/reports/${id}`
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        method: 'PATCH',
        body: JSON.stringify({
          emailClient,
          email,
          userId: data.userId,
          description: report,
          closed: true,
        }),
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            toaster.success(t('messages:updateSuccess'), { autoClose: 5000, closeButton: true })
          } else {
            toaster.error(res.message ? res?.message : t('messages:updateError'), {
              autoClose: 5000,
              closeButton: true,
            })
          }
          refetchCaseDetail()
          setIsLoading(false)
        })
        .catch((error) => {
          console.error('[UPDATE CASE REPORT ERROR]', error)
          toaster.error(error.message ? error.message : t('messages:updateError'), {
            autoClose: 5000,
            closeButton: true,
          })
          setIsLoading(false)
        })
    } else {
      toaster.success(t('messages:updateSuccess'), { autoClose: 5000, closeButton: true })
      setIsLoading(false)
    }
  }

  const updateCase = ({ comment, status, label, category }) => {
    setIsLoading(true)

    if (!process.env.REACT_APP_IS_DEMO) {
      const urlComment = `${process.env.REACT_APP_BASEURL}/caseManagement/${id}/comments`
      const url = `${process.env.REACT_APP_BASEURL}/caseManagement/${id}`
      const optionsComment = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        method: 'POST',
        body: JSON.stringify({
          description: comment || 'Closed without comment.',
          emailClient,
          email,
        }),
      }

      const optionsCase = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        method: 'PATCH',
        body: JSON.stringify({
          emailClient,
          assignedRoles: assignedRoles.map((item) => item._id),
          ...(data.status !== status && status !== '' && { status }),
          ...(data.label !== label && label !== '' && { label }),
          ...(data.category !== category && category !== '' && { category }),
          reports,
        }),
      }

      fetch(url, optionsCase)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            if (res.isCPO) {
              setAux(true)
              setIsLoading(false)
              return toaster.warning(res.message, { autoClose: 5000, closeButton: true })
            } else {
              toaster.success(t('messages:updateSuccess'), { autoClose: 5000, closeButton: true })
              fetch(urlComment, optionsComment).catch((error) => {
                console.error('[COMMENT CASE ERROR] --> ', error)
              })
            }
          } else {
            toaster.error(res.data && res?.data?.message ? res?.data?.message : t('messages:updateError'), {
              autoClose: 5000,
              closeButton: true,
            })
          }

          refetchCaseDetail()
          setIsLoading(false)
        })
        .catch((error) => {
          console.error('[CLOSE CASE ERROR] --> ', error)
          toaster.error(error.message ? error.message : t('messages:updateError'), {
            autoClose: 5000,
            closeButton: true,
          })
          setIsLoading(false)
        })

      // setOpenCloseCase(false)
      setCloseCaseModal(false)
    } else {
      toaster.success(t('messages:updateSuccess'), { autoClose: 5000, closeButton: true })
      setIsLoading(false)
      // setOpenCloseCase(false)
      setCloseCaseModal(false)
    }
  }

  // USER STATUS
  const handleUserStateChange = () => {
    setIsEditingState(true)

    if (!process.env.REACT_APP_IS_DEMO) {
      let body
      let url

      if (user?.type === 'consumer') {
        url = `${process.env.REACT_APP_BASEURL}/kyt/createConsumerUserEvent`
        body = {
          userId: data?.userId,
          updatedConsumerUserAttributes: {
            userStateDetails: {
              state: userState ? userState : '',
            },
          },
        }
      } else if (user?.type === 'business') {
        url = `${process.env.REACT_APP_BASEURL}/kyt/createBusinessUserEvent`
        body = {
          userId: data.userId,
          updatedBusinessUserAttributes: {
            userStateDetails: {
              state: userState ? userState : '',
            },
          },
        }
      }

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(body),
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            if (res.isCPO) {
              setUserState(user?.userStateDetails?.state)
              setAux(true)
              setIsEditingState(false)
              return toaster.warning(res.message, { autoClose: 5000, closeButton: true })
            } else {
              toaster.success(t('messages:updateSuccess'), { autoClose: 5000, closeButton: true })
            }
          } else {
            setUserState(user?.userStateDetails?.state ?? '')
            toaster.error(res.data && res?.data?.message ? res?.data?.message : t('messages:updateError'), {
              autoClose: 5000,
              closeButton: true,
            })
          }

          setIsEditingState(false)
          setOpenUserAction(false)
          refetchCaseDetail()
          setIsLoading(false)
        })
        .catch((error) => {
          console.error('[UPDATE USER ERROR]', error)
          setIsEditingState(false)
          toaster.error(error.message ? error.message : t('messages:updateError'), {
            autoClose: 5000,
            closeButton: true,
          })
        })
    } else {
      toaster.success(t('messages:updateSuccess'), { autoClose: 5000, closeButton: true })
      setUserState('CREATED')
      setIsEditingState(false)
      setOpenUserAction(false)
    }
  }

  // ASSIGNED ROLES
  const updateAssigns = (assignedRoles2) => {
    setIsEditingAssign(true)
    setMessage('Updating case...')

    if (!process.env.REACT_APP_IS_DEMO) {
      const url = `${process.env.REACT_APP_BASEURL}/caseManagement/${id}`
      const options = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ assignedRoles: assignedRoles2 }),
      }
      setMessage('Updating case...')
      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            if (res.isCPO) {
              setAux(true)
              setIsEditingAssign(false)
              return toaster.warning(res.message, { autoClose: 5000, closeButton: true })
            } else {
              toaster.success(t('messages:updateSuccess'), { autoClose: 5000, closeButton: true })
            }
          } else {
            toaster.error(res.data && res?.data?.message ? res?.data?.message : t('messages:updateError'), {
              autoClose: 5000,
              closeButton: true,
            })
          }

          setIsEditingAssign(false)
          refetchCaseDetail()
          setIsLoading(false)
        })
        .catch((error) => {
          console.error('[UPDATE CASE ASSIGNED USERS ERROR]', error)
          setIsEditingAssign(false)
          toaster.error(
            error.message ? error.message : 'Update error! You probably do not have role enough to edit assig users.',
            {
              autoClose: 5000,
              closeButton: true,
            },
          )
        })
    } else {
      toaster.success(t('messages:updateSuccess'), { autoClose: 5000, closeButton: true })
      setIsEditingAssign(false)
    }
  }

  // FILES
  const uploadFile = (file) => {
    setIsProcessingFile(true)
    if (hasPermissions || isAssigned) {
      if (!process.env.REACT_APP_IS_DEMO) {
        const formData = new FormData()
        formData.append('file', file)

        let url = `${process.env.REACT_APP_BASEURL}/caseManagement/${id}/file`

        const options = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        }

        fetch(url, options)
          .then((res) => res.json())
          .then((res) => {
            if (res.success) {
              toaster.success(t('messages:uploadFileSuccess'), { autoClose: 5000, closeButton: true })
            } else {
              toaster.error(
                res.data?.responseBody?.message
                  ? res.data?.responseBody?.message
                  : res.data?.info
                  ? res.data?.info
                  : res.message
                  ? res.message
                  : t('messages:createError'),
                {
                  autoClose: 5000,
                  closeButton: true,
                },
              )
            }
            setIsProcessingFile(false)
            refetchCaseDetail()
            setIsLoading(false)
          })
          .catch((error) => {
            console.error('[UPLOAD CASE FILE ERROR] --> ', error)
            setIsProcessingFile(false)
            toaster.error(error.message ? error.message : t('messages:uploadFileError'), {
              autoClose: 5000,
              closeButton: true,
            })
          })
      } else {
        setIsProcessingFile(false)
        toaster.success(t('messages:uploadFileSuccess'), { autoClose: 5000, closeButton: true })
        refetchCaseDetail()
        setIsLoading(false)
      }
    } else {
      setIsProcessingFile(false)
      toaster.error(t('messages:dontHavePermissionsAction'), {
        autoClose: 5000,
        closeButton: true,
      })
    }
  }

  const deleteFile = (e, file) => {
    e.preventDefault()
    setIsProcessingFile(true)
    if (!process.env.REACT_APP_IS_DEMO) {
      const splittedName = file.split('/case/')[1].split('?')
      const key = splittedName[splittedName.length - 2]

      let url = `${process.env.REACT_APP_BASEURL}/caseManagement/${id}/file/${key}`

      const options = {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            toaster.success(t('messages:deletionSuccess'), { autoClose: 5000, closeButton: true })
          } else {
            toaster.error(
              res.data?.responseBody?.message
                ? res.data?.responseBody?.message
                : res.data?.info
                ? res.data?.info
                : res.message
                ? res.message
                : t('messages:createError'),
              {
                autoClose: 5000,
                closeButton: true,
              },
            )
          }
          setIsProcessingFile(false)
          refetchCaseDetail()
          setIsLoading(false)
        })
        .catch((error) => {
          console.error('[DELETE CASE FILE ERROR] --> ', error)
          setIsProcessingFile(false)
          toaster.error(error.message ? error.message : t('messages:deletionError'), {
            autoClose: 5000,
            closeButton: true,
          })
        })
    } else {
      setIsProcessingFile(false)
      toaster.success(t('messages:deletionSuccess'), { autoClose: 5000, closeButton: true })
      refetchCaseDetail()
      setIsLoading(false)
    }
  }

  // WORKFLOWS
  const assignWorkflow = () => {
    if (workflow) {
      setIsLoading(true)
      setViewToast(true)
      const url = `${process.env.REACT_APP_BASEURL}/caseManagement/${id}`

      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        method: 'PATCH',
        body: JSON.stringify({
          emailClient,
          assignedWorkflow: workflow.id,
          ...(workflow.steps[0].assignedRoles.length > 0 && {
            assignedRoles: workflow.steps[0].assignedRoles,
          }),
          ...(workflow.steps[0].caseStatus !== '' && {
            status: workflow.steps[0].caseStatus,
          }),
          ...(workflow.steps[0].label !== '' && { label: workflow.steps[0].label }),
          ...(workflow.steps[0].category !== '' && { category: workflow.steps[0].category }),
          actualStep: 0,
        }),
      }

      fetch(process.env.REACT_APP_IS_DEMO ? '../../data/caseDetails.json' : url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            toaster.success(t('messages:updateSuccess'), { autoClose: 5000, closeButton: true })
          } else {
            toaster.error(res.message ? res?.message : t('messages:updateError'), {
              autoClose: 5000,
              closeButton: true,
            })
          }
          setWorkflowModal(false)
          refetchCaseDetail()
          setIsLoading(false)
        })
        .catch((error) => {
          console.error('[UPDATE CASE] --> ', error)
          setMessage(error.message ? error.message : `${t('messages:updateError')}`)
          setErrorAssign(true)
          setWorkflowModal(false)
        })

      setTimeout(() => {
        setViewToast(false)
      }, 3000)
    }
  }

  const scaleCase = () => {
    if (workflow) {
      let scalePoint = isDownscale ? actualStep - 1 : actualStep + 1
      setIsLoading(true)
      const url = `${process.env.REACT_APP_BASEURL}/caseManagement/${id}`
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        method: 'PATCH',
        body: JSON.stringify({
          emailClient,
          assignedWorkflow: workflow.id,
          ...(workflow.steps[scalePoint].assignedRoles.length > 0 && {
            assignedRoles: workflow.steps[scalePoint].assignedRoles,
          }),
          ...(workflow.steps[scalePoint].caseStatus !== '' && {
            status: workflow.steps[scalePoint].caseStatus,
          }),
          ...(workflow.steps[scalePoint].label !== '' && { label: workflow.steps[scalePoint].label }),
          ...(workflow.steps[scalePoint].category !== '' && { category: workflow.steps[scalePoint].category }),
          actualStep: scalePoint,
        }),
      }

      fetch(process.env.REACT_APP_IS_DEMO ? '../../data/caseDetails.json' : url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            toaster.success(t('messages:updateSuccess'), { autoClose: 5000, closeButton: true })
            setScaleModal(false)
            setIsDownscale(false)
            refetchCaseDetail()
            setIsLoading(false)
          } else {
            toaster.error(res.data && res?.data?.message ? res?.data?.message : t('messages:updateError'), {
              autoClose: 5000,
              closeButton: true,
            })
          }
        })
        .catch((error) => {
          console.error('[UPDATE CASE] --> ', error)
          toaster.error(error.message ? error.message : t('messages:updateError'), {
            autoClose: 5000,
            closeButton: true,
          })
          setScaleModal(false)
          setIsDownscale(false)
        })
    }
  }

  useEffect(() => {
    if (aux) {
      setAssignedRoles([])
      setAux(false)
    }
  }, [aux])

  useEffect(() => {
    if (hasPermissions && data?.status === 'PENDING' && caseStatusFinal.includes('REVIEW_IN_PROGRESS')) {
      setOpenUpdateMessage(true)
    } else {
      setOpenUpdateMessage(false)
    }
  }, [hasPermissions, data?.status, caseStatusFinal])

  // OPEN CASE
  const reOpenCase = ({ comment }) => {
    setIsLoading(true)

    if (!process.env.REACT_APP_IS_DEMO) {
      const urlComment = `${process.env.REACT_APP_BASEURL}/caseManagement/${id}/comments`
      const url = `${process.env.REACT_APP_BASEURL}/caseManagement/openCase/${id}`
      const optionsComment = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        method: 'POST',
        body: JSON.stringify({
          description: comment !== '' ? comment : 'Case re-opened without comment.',
          emailClient,
          email,
        }),
      }

      setOpenCaseModal(false)

      const optionsCase = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        method: 'PATCH',
        body: JSON.stringify({
          status: 'REVIEW_IN_PROGRESS',
        }),
      }

      fetch(url, optionsCase)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            if (res.isCPO) {
              setAux(true)
              setIsLoading(false)
              return toaster.warning(res.message, { autoClose: 5000, closeButton: true })
            } else {
              toaster.success(t('messages:updateSuccess'), { autoClose: 5000, closeButton: true })
              fetch(urlComment, optionsComment).catch((error) => {
                console.error('[COMMENT CASE ERROR] --> ', error)
              })
            }
          } else {
            toaster.error(res.data && res?.data?.message ? res?.data?.message : t('messages:updateError'), {
              autoClose: 5000,
              closeButton: true,
            })
          }

          refetchCaseDetail()
          setIsLoading(false)
        })
        .catch((error) => {
          console.error('[OPEN CASE ERROR] --> ', error)
          toaster.error(error.message ? error.message : t('messages:updateError'), {
            autoClose: 5000,
            closeButton: true,
          })
        })
    } else {
      toaster.success(t('messages:updateSuccess'), { autoClose: 5000, closeButton: true })
      setIsLoading(false)
      setOpenCaseModal(false)
    }
  }

  if (isLoadingCaseDetail || isLoadingCaseUser || isLoadingCaseAdminPanel || isLoadingWorkflows) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box className={`${(isFetchingCaseDetail || isFetchingCaseUser) && 'spinner-transition'} filter`}>
      {viewToast && <AlertFetchSpinner message={message} error={errorAssign} />}
      {isLoading && <Spinner />}
      {user && (
        <Box className="kyb-detail">
          <Box className="kyb-detail-head">
            <Paper elevation={0} sx={{ p: '1rem', mr: '1rem' }}>
              <Box className="kyb-info">
                <Box className="kyb-info-section-head">
                  <Box className="kyb-info-section-1">
                    {/* NOMBRE */}
                    <Typography variant="h4">{t('common:caseDetail')}</Typography>
                    {/* CHIP */}
                    <Chip
                      label={data?.label ?? t('common:notDefined')}
                      icon={<WorkOutlineOutlinedIcon />}
                      sx={{ backgroundColor: '#ffd7d4', color: '#ff8a00' }}
                    />
                  </Box>

                  {assignedWorkflow !== '' && workflow.steps?.length > 1 && !caseIsClosed && (
                    <Box className="kyb-info-section-1">
                      {!isInitialStep && (
                        <Button
                          onClick={() => {
                            setScaleModal(true)
                            setIsDownscale(true)
                          }}
                          variant="label"
                          startIcon={<UndoOutlinedIcon />}
                          disabled={!hasPermissions && !isAssigned}
                        >
                          {t('common:downscale')}
                        </Button>
                      )}

                      {!isFinalStep && (
                        <Button
                          onClick={() => setScaleModal(true)}
                          variant="label"
                          endIcon={<RedoOutlinedIcon />}
                          disabled={!hasPermissions && !isAssigned}
                        >
                          {t('common:scale')}
                        </Button>
                      )}

                      {isFinalStep && (
                        <Button
                          onClick={() => setCloseCaseModal(true)}
                          variant="action"
                          disabled={!hasPermissions && !isAssigned}
                        >
                          {t('common:closeCase')}
                        </Button>
                      )}
                    </Box>
                  )}

                  {caseIsClosed && (
                    <Button
                      onClick={() => setOpenCaseModal(true)}
                      variant="action"
                      disabled={!hasPermissions && !isAssigned}
                    >
                      {t('common:reopenCase')}
                    </Button>
                  )}

                  {workflow.steps?.length === 1 && !caseIsClosed && (
                    <Button
                      onClick={() => setCloseCaseModal(true)}
                      variant="action"
                      disabled={!hasPermissions && !isAssigned}
                    >
                      {t('common:closeCase')}
                    </Button>
                  )}
                </Box>
                <Box className="kyb-info-section-4">
                  <Paper elevation={0} variant="colorPrimary">
                    {/* ID */}
                    <Typography variant="subtitle1">{t('common:caseId')}</Typography>
                    <Box className="copy-container">
                      <Typography variant="number">{data?.maskId ? data?.maskId : data._id}</Typography>

                      <Fab
                        size="small"
                        className="icon-global"
                        variant="action"
                        onClick={() => {
                          navigator.clipboard.writeText(data.maskId ? data.maskId : data._id)
                        }}
                      >
                        <ContentCopyOutlinedIcon />
                      </Fab>
                    </Box>
                  </Paper>

                  <Paper elevation={0} variant="colorPrimary">
                    {/* USER ID */}
                    <Typography variant="subtitle1">{t('common:userId')}</Typography>
                    <Box className="copy-container">
                      <Typography variant="number">
                        {data?.userId ? (
                          <Link
                            component={ReactRouterLink}
                            to={`/users/${data.userId}?type=${user.type}`}
                            target="_blank"
                          >
                            <Typography variant="number">{data.userId}</Typography>
                          </Link>
                        ) : (
                          '---'
                        )}
                      </Typography>

                      <Fab
                        size="small"
                        className="icon-global"
                        variant="action"
                        onClick={() => {
                          navigator.clipboard.writeText(data.userId)
                        }}
                      >
                        <ContentCopyOutlinedIcon />
                      </Fab>
                    </Box>
                  </Paper>

                  <Paper elevation={0} variant="colorPrimary">
                    {/* USER NAME */}
                    <Typography variant="subtitle1">{t('common:userName')}</Typography>
                    <Typography variant="h5">
                      {renderNames(user?.userDetails?.name ? user?.userDetails?.name : user?.legalEntity)}
                    </Typography>
                  </Paper>

                  <Paper elevation={0} variant="colorPrimary">
                    {/* USER TYPE */}
                    <Typography variant="subtitle1">{t('common:userType')}</Typography>
                    <Typography variant="h5">
                      {user?.type === 'consumer' ? 'Consumer User' : 'Business User'}
                    </Typography>
                  </Paper>

                  <Paper elevation={0} variant="colorPrimary">
                    {/* DATE */}
                    <Typography variant="subtitle1">{t('common:date')}</Typography>
                    <Typography variant="h5" style={{ textWrap: 'nowrap' }}>
                      {data?.createdAt ? buildTimeStringFromTimestamp(data.createdAt) : '---'}
                    </Typography>
                  </Paper>

                  <Paper elevation={0} variant="colorPrimary">
                    {/* STATUS */}
                    <Typography variant="subtitle1">{t('common:status')}</Typography>
                    <TableChip action={data.status} />
                  </Paper>

                  <Paper elevation={0} variant="colorPrimary">
                    {/* CATEGORY */}
                    <Typography variant="subtitle1">{t('common:category')}</Typography>
                    <Typography variant="h5">{data.category ?? '---'}</Typography>
                  </Paper>

                  <Paper elevation={0} variant="colorPrimary">
                    {/* LABEL */}
                    <Typography variant="subtitle1">{t('common:label')}</Typography>
                    <Typography variant="h5">{data.label ?? '---'}</Typography>
                  </Paper>
                </Box>
              </Box>
            </Paper>
            <Box className="details-sub2">
              {/* BOTÓN WORKFLOW */}
              {!caseIsClosed && (
                <Button
                  size="small"
                  onClick={() => setWorkflowModal(true)}
                  variant="contained"
                  disabled={
                    !activeServices.includes('workflows') ||
                    (activeServices.includes('workflows') && !isAssigned && !hasPermissions)
                  }
                >
                  {assignedWorkflow !== '' ? t('common:changeWorkflow') : t('common:assignWorkflow')}
                </Button>
              )}

              {/* BOTÓN EXPORT REPORT */}
              <Button
                variant="outlined"
                disabled={(!hasPermissions && !isAssigned) || !activeServices.includes('reportsitem')}
                onClick={() => setShowDownloadModal(true)}
                endIcon={<IosShareOutlinedIcon sx={{ fill: 'var(--lime-04)!important' }} />}
              >
                <Typography variant="title2">{t('common:exportReport')}</Typography>
              </Button>

              {/* BOTÓN BDC INFORMATION */}
              {data && data.userId && emailClient && emailClient === 'bancodecomercio@gueno.com' && (
                <Button
                  variant="outlinedGrey"
                  disabled={!hasPermissions && !isAssigned}
                  onClick={() => window.open(`${process.env.REACT_APP_REVEAL_INFORMATION_URL}${data.userId}`, '_blank')}
                >
                  {t('common:revealInformation')}
                </Button>
              )}

              {/* BOTÓN UPDATE CASE */}
              <Button
                size="small"
                onClick={() => setCloseCaseModal(true)}
                variant="outlinedGrey"
                disabled={(!hasPermissions && !isAssigned) || caseIsClosed}
              >
                {t('common:updateCase')}
              </Button>

              <Paper elevation={0} sx={{ p: '1rem' }} className="risk-level">
                {/* BOTÓN USER ACTION */}
                <Box className="risk-level-top">
                  <Typography variant="subtitle2">{t('common:userAction')}</Typography>
                  <Button
                    size="small"
                    onClick={() => setOpenUserAction(true)}
                    variant="outlined"
                    disabled={!hasPermissions || !canEditUserAction || !isAssigned || caseIsClosed}
                  >
                    {t('common:edit')}
                  </Button>
                </Box>
                {userState ? <StatusIndicator type={userState} /> : <StatusIndicator type={'NOT_STARTED'} />}
              </Paper>
            </Box>
          </Box>

          <Paper elevation={0} sx={{ p: '1rem' }}>
            <SectionCreateReport
              hasPermissions={hasPermissions}
              lastReport={lastReport}
              isAssigned={isAssigned}
              caseIsClosed={caseIsClosed}
              onCloseReport={closeReport}
              onCreateReport={createReport}
              onUpdateReport={updateReport}
              caseId={id}
            />
          </Paper>

          <Box className="kyb-subgrid">
            <TabContext value={selectedTab}>
              <CustomTabList
                TabIndicatorProps={{
                  style: { display: 'none' },
                }}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
              >
                {tabList &&
                  tabList.length &&
                  tabList.map(({ key, label }) => (
                    <Tab
                      key={key}
                      label={<Chip label={label} variant={selectedTab === key ? 'sliderBlack' : 'sliderWhite'} />}
                      value={key}
                    />
                  ))}
              </CustomTabList>

              <Box>
                <Paper elevation={0}>
                  <TabPanel value="cases">
                    <TabUserCases userId={data.userId} />
                  </TabPanel>

                  {activeServices.includes('generalAlerts') && (
                    <TabPanel value="transactionAlerts">
                      <TabTransactionsAlerts id={id} hideActions={true} hasPermissions={hasPermissions} />
                    </TabPanel>
                  )}

                  <TabPanel value="transactions">
                    <TabTransactions id={id} />
                  </TabPanel>

                  <TabPanel value="assignedRoles">
                    <Box className="kyb-card">
                      <Box className="card-top">
                        <Typography variant="title">{t('common:assignedRoles')}</Typography>
                        <Box>
                          {!isEditingAssign ? (
                            <Button
                              onClick={() => {
                                setIsEditingAssign(true)
                              }}
                              size="small"
                              variant="contained"
                              disabled={!hasPermissions || caseIsClosed}
                            >
                              {t('common:addRole')}
                            </Button>
                          ) : (
                            <Fab variant="close" onClick={() => setIsEditingAssign(false)}>
                              <CloseOutlinedIcon />
                            </Fab>
                          )}
                        </Box>
                      </Box>
                      <DatatableAssignRoles
                        rows={assignedRoles}
                        toAssign={rolesState?.data}
                        isEditing={isEditingAssign}
                        updateAssigns={updateAssigns}
                        setIsEditingAssign={setIsEditingAssign}
                        errorAssign={errorAssign}
                      />
                    </Box>
                  </TabPanel>

                  <TabPanel value="checklists">
                    <Box className="kyb-detail-documents">
                      <Box className="kyb-documents-top">
                        <Typography variant="title">{t('common:checklists')}</Typography>
                      </Box>

                      <TabChecklists hasPermissions={hasPermissions} caseId={id} />
                    </Box>
                  </TabPanel>

                  <TabPanel value="documents">
                    <Box className="kyb-detail-documents">
                      <Box className="kyb-documents-top">
                        <Typography variant="title">{t('common:documents')}</Typography>
                        <Typography variant="subtitle3">{t('messages:updatePDFRelated')}</Typography>
                      </Box>

                      <Box className="kyb-files-drag">
                        <Typography variant="title2">{t('common:addAFile')}</Typography>
                        <FileUploader
                          handleChange={(file) => uploadFile(file)}
                          name="file"
                          disabled={(!hasPermissions && !isAssigned) || caseIsClosed}
                          label={t('common:fileUploaderLabel')}
                          types={['JPG', 'JPEG', 'PNG', 'GIF', 'PDF', 'EML', 'MP4', 'MP3', 'MPEG', 'WEBM', 'WAV']}
                        >
                          <Paper elevation={0} variant="rootDashedTransparent" className="draggable-main">
                            <Box
                              className={!hasPermissions && !isAssigned ? 'draggable-icon-disabled' : 'draggable-icon'}
                            ></Box>
                            <Typography variant="title2">{t('common:fileUploaderLabel')}</Typography>
                            <Box>
                              <Typography variant="subtitle4">{t('common:or')} </Typography>
                              <Typography
                                sx={{ textDecoration: 'underline' }}
                                variant="subtitle4"
                                color={!hasPermissions && !isAssigned ? '#66766b' : '#00c43d'}
                              >
                                {t('common:browseMedia')}
                              </Typography>
                            </Box>
                          </Paper>
                        </FileUploader>
                        <Typography variant="subtitle4">
                          {t('common:supportedMedia')}: JPG, PNG, PDF, EML, GIF, MP4, MP3, MPEG, WEBM, WAV.
                        </Typography>
                      </Box>

                      <Box className="kyb-file-list-wrapper">
                        <Typography variant="title"> {t('common:uploadedDocuments')}</Typography>
                        <Box className="kyb-file-list">
                          {isProcessingFile ? (
                            <ProcessingSpinner message={t('common:processing')} />
                          ) : (
                            <Box>
                              {Array.isArray(data?.files) && data.files.length > 0 && (
                                <Box className="kyb-files">
                                  {data.files.map((file, index) => {
                                    const splittedName = file.split('/case/')[1].split('_')
                                    const fileName = splittedName[splittedName.length - 2]?.replaceAll('%20', ' ')
                                    return (
                                      <Paper elevation={0} variant="colorPrimary" className="kyb-file" key={index}>
                                        <Box className="kyb-file-description">
                                          <Box className="color-button">
                                            <Typography variant="subtitle1" sx={{ color: '#FF2B1B' }}>
                                              FILE
                                            </Typography>
                                          </Box>

                                          <Typography variant="text2" title={fileName}>
                                            {fileName}
                                          </Typography>
                                        </Box>
                                        <Box className="kyb-file-icons">
                                          <Button
                                            variant="outlined"
                                            size="small"
                                            onClick={() => window.open(file, '_blank')}
                                          >
                                            {t('common:view')}
                                          </Button>

                                          <Button
                                            variant="outlinedBlank"
                                            disabled={(!hasPermissions && !isAssigned) || caseIsClosed}
                                            onClick={(e) => deleteFile(e, file)}
                                          >
                                            <DeleteOutlineIcon />
                                          </Button>
                                        </Box>
                                      </Paper>
                                    )
                                  })}
                                </Box>
                              )}
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </TabPanel>

                  <TabPanel value="comments">
                    <Box className="kyb-card">
                      <Box className="card-top">
                        <Typography variant="title">{t('common:comments')}</Typography>
                        {/* BOTÓN COMENTARIOS */}
                        <Button
                          size="small"
                          onClick={() => setModalOpen(true)}
                          variant="contained"
                          disabled={(!hasPermissions && !isAssigned) || caseIsClosed}
                        >
                          {t('common:postComment')}
                        </Button>
                      </Box>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>{t('common:email')}</TableCell>
                              <TableCell>{t('common:createdAt')}</TableCell>
                              <TableCell>{t('common:comment')}</TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {Array.isArray(data?.comments) && data?.comments?.length > 0 ? (
                              data.comments.map((row, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell>
                                      <Box>{row.email ?? '---'}</Box>
                                    </TableCell>
                                    <TableCell>
                                      {row.createdAt ? buildTimeStringFromTimestamp(row.createdAt) : '---'}
                                    </TableCell>
                                    <TableCell>{row.description ?? '---'}</TableCell>
                                  </TableRow>
                                )
                              })
                            ) : (
                              <Box className="table-custom-overlay">
                                <CustomNoRowsOverlay />
                              </Box>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </TabPanel>

                  <TabPanel value="reports">
                    <TabReports reports={data?.reports} isLoading={isLoading} />
                  </TabPanel>

                  <TabPanel value="timeline">
                    <TabTimeline />
                  </TabPanel>
                </Paper>
              </Box>
            </TabContext>
          </Box>
        </Box>
      )}

      {/* DATA EXPORT MODAL */}

      <Modal open={showDownloadModal} onClose={() => setShowDownloadModal(false)}>
        <div>
          <ModalExportReport caseDetail={data} userDetail={user} onClose={() => setShowDownloadModal(false)} />
        </div>
      </Modal>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <div>
          <ModalCreateComment onClose={() => setModalOpen(false)} onSubmit={postNewComment} />
        </div>
      </Modal>

      {/* MODAL WORKFLOW */}
      <Modal open={workflowModal} onClose={() => setWorkflowModal(false)}>
        <Box className="modal">
          <Box className="modal-top">
            <Box className="modal-titles">
              <Typography variant="title">{t('common:assignWorkflow')}</Typography>
            </Box>
            <Fab variant="close" onClick={() => setWorkflowModal(false)}>
              <CloseOutlinedIcon />
            </Fab>
          </Box>

          <Box className="case-workflow-info">
            <Typography variant="subtitle3">{t('messages:assignWorkflowInfo')}</Typography>
          </Box>

          <Box className="modal-box">
            <InputLabel>{t('common:select')}:</InputLabel>
            <Select fullWidth size="small" value={workflow} onChange={(e) => setWorkflow(e.target.value)} displayEmpty>
              <MenuItem disabled value="">
                <Typography variant="subtitle3">
                  {t('common:select')} {t('common:workflow')}
                </Typography>
              </MenuItem>
              {Array.isArray(workflows) &&
                workflows.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          </Box>
          <Box className="modal-filter-buttons">
            <Button variant="outlinedGrey" onClick={() => setWorkflowModal(false)}>
              {t('common:close')}
            </Button>
            <Button disabled={workflow === ''} variant="contained" onClick={assignWorkflow}>
              {t('common:save')}
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* MODAL SCALE */}
      <Modal open={scaleModal} onClose={() => setScaleModal(false)}>
        <div>
          <ModalScaleCase
            onClose={() => setScaleModal(false)}
            actualStep={actualStep}
            onSubmit={({ comment }) => {
              postNewComment({ comment })
              return scaleCase()
            }}
          />
        </div>
      </Modal>

      <Modal open={closeCaseModal} onClose={() => setCloseCaseModal(false)}>
        <div>
          <ModalCloseCase onClose={() => setCloseCaseModal(false)} onSubmit={updateCase} />
        </div>
      </Modal>

      {/* MODAL OPEN CASE */}
      <Modal open={openCaseModal} onClose={() => setOpenCaseModal(false)}>
        <div>
          <ModalOpenCase onClose={() => setOpenCaseModal(false)} onSubmit={reOpenCase} />
        </div>
      </Modal>

      {/* MODAL USER STATUS */}
      <Modal
        open={openUserAction}
        onClose={() => {
          setOpenUserAction(false)
          setUserState(user?.userStateDetails?.state ?? '')
        }}
      >
        {isEditingState ? (
          <ProcessingSpinner message={t('common:processing')} />
        ) : (
          <Box className="modal">
            <Box className="modal-top">
              <Box className="modal-titles">
                <Typography variant="title">
                  {t('common:update')} {t('common:status')}
                </Typography>
              </Box>
              <Fab
                variant="close"
                onClick={() => {
                  setOpenUserAction(false)
                  setUserState(user?.userStateDetails?.state ?? '')
                }}
              >
                <CloseOutlinedIcon />
              </Fab>
            </Box>

            <Box className="modal-box-filters">
              <Box className="modal-box">
                <Typography variant="subtitle3">{t('messages:doYouWantUpdateUserStatus')}?</Typography>
              </Box>
            </Box>

            <Box className="modal-box">
              <InputLabel>{t('common:newStatus')}:</InputLabel>
              <Select
                fullWidth
                size="small"
                value={userState}
                onChange={(e) => {
                  if (e.target.value) {
                    setUserState(e.target.value)
                  } else setUserState('')
                }}
                displayEmpty
              >
                <MenuItem disabled value="">
                  <Typography variant="subtitle3">
                    {t('common:select')} {t('common:newStatus')}
                  </Typography>
                </MenuItem>
                {userStateValues?.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box className="modal-filter-buttons">
              <Button
                variant="outlinedGrey"
                onClick={() => {
                  setOpenUserAction(false)
                  setUserState(user?.userStateDetails?.state ?? '')
                }}
              >
                {t('common:close')}
              </Button>
              <Button disabled={userState === ''} variant="contained" onClick={handleUserStateChange}>
                {t('common:save')}
              </Button>
            </Box>
          </Box>
        )}
      </Modal>

      <Modal open={closeCaseModal} onClose={() => setCloseCaseModal(false)}>
        <div>
          <ModalUpdateCase
            onClose={() => setCloseCaseModal(false)}
            labels={caseAdminPanel.labels}
            categories={caseAdminPanel.categories}
            caseStatusFinal={caseStatusFinal}
            onSubmit={updateCase}
          />
        </div>
      </Modal>

      <Modal open={openUpdateMessage} onClose={() => setOpenUpdateMessage(false)}>
        <Box className="modal">
          <Box className="modal-top">
            <Box className="modal-titles">
              <Typography variant="title">{t('messages:case.askForUpdate.title')}</Typography>
            </Box>
            <Fab variant="close" onClick={() => setOpenUpdateMessage(false)}>
              <CloseOutlinedIcon />
            </Fab>
          </Box>

          <Box className="modal-box">
            <Box className="modal-flex">
              <Typography>{t('messages:case.askForUpdate.message')}</Typography>
            </Box>
            <Box className="modal-filter-buttons">
              <Button variant="outlined" onClick={() => setOpenUpdateMessage(false)}>
                {t('common:no')}
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  updateCase({ status: 'REVIEW_IN_PROGRESS', comment: `${t('messages:case.askForUpdate.comment')}` })
                }}
              >
                {t('common:yes')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default Case
