import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link as ReactRouterLink } from 'react-router-dom'
import CircleIcon from '@mui/icons-material/Circle'
import dayjs from 'dayjs'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Link,
  Modal,
  Paper,
  TablePagination,
  Tooltip,
  Typography,
} from '@mui/material'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import RefreshIcon from '@mui/icons-material/Refresh'
import ClearIcon from '@mui/icons-material/Clear'
import { Edit, Visibility } from '@mui/icons-material'
import {
  defaultCasesFilters,
  resetActionAlertFilter,
  resetCasesFilters,
  resetStatusFilter,
  toogleShowMyCases,
  updateCasesFilters,
  updateSelectedCases,
  useFindCasesQuery,
  useUpdateCasesMutation,
} from '../../../features/cases/caseSlice'
import { useLazyGetKytUserByIdQuery } from '../../../features/transactions/transactionSlice'
import CustomColumnMenu from '../../../components/common/datagrid/CustomColumnMenu'
import CustomLoadingOverlay from '../../../components/common/datagrid/CustomLoadingOverlay'
import { TableChip } from '../../../components/utilities/TableChip'
import { buildTimeStringFromTimestamp, toLocaleUTCDateString } from '../../../components/common/time/timeHelper'
import FiltersCasesForm from './FiltersCasesForm'
import toaster from '../../../toaster'
import CaseSearch from './components/CaseSearch'
import { selectUserMaster } from '../../../features/kyb/kybCountrySlice'
import Status from './components/Status'
import FilterMenu from '../../../components/common/FilterMenu/FilterMenu'
import { formatDate } from '../../kyt/utils'
import EditCasesForm from './components/EditCasesForm'
import useUserTableConfig from '../../../components/hooks/useUserTableConfig'
import {
  getOrderedColumns,
  getVisibilityModel,
  updateListOrder,
} from '../../../components/common/datatable/tableUtils/tableUtils'
import styles from './CasesPage.module.scss'

const CasesPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'messages'])
  const navigate = useNavigate()
  const { emailClient, email, roles } = useSelector((state) => state.login)
  const queryState = useSelector((state) => state.case.filters)
  const selectedCases = useSelector((state) => state.case.selectedCases)
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [openEditCasesForm, setOpenEditCasesForm] = useState()
  const [page, setPage] = useState(0)
  const userMaster = useSelector((state) => selectUserMaster(state, emailClient))
  const timezone = useMemo(
    () => (typeof userMaster?.timezone !== 'number' ? 0 : userMaster?.timezone),
    [userMaster?.timezone],
  )

  const [updateCases, { isLoading: isUpdatingCases }] = useUpdateCasesMutation()
  const [getKytUserById] = useLazyGetKytUserByIdQuery()
  const statuses = [
    {
      label: t('common:pending'),
      value: 'pending',
    },
    {
      label: t('common:opened'),
      value: 'opened',
    },
    {
      label: t('common:closed'),
      value: 'closed',
    },
  ]

  const alertActions = [
    {
      label: t('common:allowed'),
      value: 'ALLOW',
    },
    {
      label: t('common:flagged'),
      value: 'FLAG',
    },
    {
      label: t('common:suspended'),
      value: 'SUSPEND',
    },
    {
      label: t('common:blocked'),
      value: 'BLOCK',
    },
  ]

  const handleUpdateSelected = (ids) => {
    dispatch(updateSelectedCases(ids))
  }

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        minWidth: 140,
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <Link
              component={ReactRouterLink}
              to={`/case-management/case/${row.id}`}
              title={row.maskId ? row.maskId : row.id}
              style={{ wordBreak: 'break-word' }}
            >
              <Typography variant="number">{row.maskId || row.id}</Typography>
            </Link>
          )
        },
      },
      {
        field: 'userId',
        headerName: t('common:userId'),
        minWidth: 140,
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <Link onClick={() => handleGoToUser(row.userId)} style={{ wordBreak: 'break-word' }}>
              <Typography variant="number">{row.userId}</Typography>
            </Link>
          )
        },
      },
      {
        field: 'name',
        headerName: t('common:name'),
        minWidth: 200,
        sortable: true,
        renderCell: ({ row }) => {
          return row.name ?? '---'
        },
      },
      {
        field: 'transactions',
        headerName: t('common:transactions'),
        minWidth: 100,
        sortable: true,
        renderCell: ({ row }) => {
          return <Typography variant="number">{row.transactions || 0}</Typography>
        },
      },
      {
        field: 'generalAlerts',
        headerName: t('common:alerts'),
        minWidth: 100,
        sortable: true,
        renderCell: ({ row }) => {
          return <Typography variant="number">{row.generalAlerts || 0}</Typography>
        },
      },
      {
        field: 'uniqueAlertCase',
        headerName: t('common:uniqueAlertCase'),
        minWidth: 100,
        sortable: false,
        renderCell: ({ row }) => {
          return <TableChip noIcon={true} action={row.uniqueAlertCase ? 'yes' : 'no'} />
        },
      },
      {
        field: 'status',
        headerName: t('common:caseStatus'),
        minWidth: 185,
        sortable: false,
        renderCell: ({ row }) => {
          return <Status status={row.status} />
        },
      },
      {
        field: 'category',
        headerName: t('common:category'),
        minWidth: 150,
        sortable: false,
      },
      {
        field: 'label',
        headerName: t('common:label'),
        minWidth: 200,
        sortable: false,
      },
      {
        field: 'assignedRoles',
        headerName: t('common:assignedRoles'),
        minWidth: 240,
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <Box className="cellOverflow">
              {row.assignedRoles?.length ? row.assignedRoles?.map((item) => item.roleName).join(', ') : '---'}
            </Box>
          )
        },
      },
      {
        field: 'checklistInstanceIds',
        headerName: t('common:checklists'),
        minWidth: 150,
        sortable: true,
        renderCell: ({ row }) => {
          return <Typography variant="number">{row.checklistInstanceIds || 0}</Typography>
        },
      },
      {
        field: 'createdAt',
        headerName: t('common:createdAt'),
        minWidth: 200,
        sortable: true,
        renderCell: ({ row }) => {
          return <Typography variant="number">{toLocaleUTCDateString(row.createdAt)}</Typography>
        },
      },
      {
        field: 'updatedAt',
        headerName: t('common:updatedAt'),
        minWidth: 200,
        sortable: true,
        renderCell: ({ row }) => {
          return <Typography variant="number">{buildTimeStringFromTimestamp(row.updatedAt)}</Typography>
        },
      },
    ],
    [selectedCases],
  )

  const {
    config,
    saveConfig,
    loading: configLoading,
  } = useUserTableConfig({
    tableKey: 'caseManagement',
    defaultConfig: {
      sortModel: [{ field: 'updatedAt', sort: 'desc' }],
      rowsPerPage: 25,
      pinnedColumns: { left: [], right: [] },
      columnOrder: columns.map((c) => c.field),
    },
  })

  const { field, sort } = useMemo(
    () => (config.sortModel?.length ? config.sortModel[0] : { field: '', sort: '' }),
    [config.sortModel],
  )

  const {
    data: allCases,
    isLoading: isLoadingCases,
    isFetching: isFetchingCases,
    refetch,
  } = useFindCasesQuery({
    ...queryState,
    emailClient,
    page,
    limit: config.rowsPerPage,
    offset: config.rowsPerPage * page,
    roles,
    sortField: field,
    timezone,
    sortDirection: sort === 'desc' ? -1 : 1,
  })
  const defaultFromDate = queryState.fromDate ? dayjs(queryState.fromDate) : null
  const defaultToDate = queryState.toDate ? dayjs(queryState.toDate) : null
  const apiRef = useGridApiRef()

  const handleResetButton = () => {
    setPage(0)
    dispatch(resetCasesFilters())
  }

  const handleToogleShowMyCases = () => {
    dispatch(toogleShowMyCases())
  }

  const handleSearch = (values) => {
    return dispatch(
      updateCasesFilters({
        viewMode: true,
        page: 0,
        limit: config.rowsPerPage,
        offSet: config.rowsPerPage * page,
        ...queryState,
        searchText: values.searchText,
        searchType: values.searchType,
      }),
    )
  }

  const handleDispatchStatus = (status) => {
    return dispatch(
      updateCasesFilters({
        ...queryState,
        status,
      }),
    )
  }

  const handleDispatchAlertAction = (alertAction) => {
    return dispatch(
      updateCasesFilters({
        ...queryState,
        alertAction,
      }),
    )
  }

  const handleResetFilterStatus = () => {
    return dispatch(resetStatusFilter())
  }

  const handleResetFilterActionAlert = () => {
    return dispatch(resetActionAlertFilter())
  }

  const handleDispatch = (values) => {
    const { fromDate, toDate, fromTime, toTime, ...filters } = values

    return dispatch(
      updateCasesFilters({
        viewMode: true,
        page,
        limit: config.rowsPerPage,
        offset: config.rowsPerPage * page,
        fromDate: formatDate(fromDate, fromTime),
        toDate: formatDate(toDate, toTime),
        isMyCases: queryState.isMyCases,
        searchText: queryState.searchText,
        searchType: queryState.searchType,
        status: queryState.status,
        ...filters,
      }),
    )
  }

  const handleChangePage = (_, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    saveConfig({ rowsPerPage: newRowsPerPage })

    setPage(0)
  }

  const handleGoToUser = async (userId) => {
    const { data: userData, isSuccess } = await getKytUserById({ userId, emailClient })
    if (isSuccess && userData.data) {
      navigate(`/users/${userId}?type=${userData.data.type}`)
    }
  }

  const handleUpdateCases = async (values) => {
    const { comment = 'Closed without comment.', status, label, category } = values

    try {
      await updateCases({
        ids: selectedCases.filter(Boolean).join(', '),
        emailClient,
        email,
        comment,
        ...(status && { status }),
        ...(label && { label }),
        ...(category && { category }),
      }).unwrap()
      refetch()
      setOpenEditCasesForm(false)
      handleUpdateSelected([])
      toaster.success(t('messages:updateSuccess'))
    } catch (error) {
      console.error('Error updating cases', error)
      toaster.error(t('messages:updateError'))
      console.error(error?.data?.data)
    }
  }

  const hasFiltersApplied = useMemo(() => {
    return Object.keys(defaultCasesFilters).some((field) => defaultCasesFilters[field] !== queryState[field])
  }, [queryState])

  const orderedColumns = useMemo(
    () => getOrderedColumns(config.columnOrder, columns),
    [JSON.stringify(config.columnOrder), columns],
  )

  const handleColumnOrderChange = (params) => {
    const updatedOrder = updateListOrder(config.columnOrder, params)
    if (JSON.stringify(config.columnOrder) !== JSON.stringify(updatedOrder)) {
      saveConfig({ columnOrder: updatedOrder })
    }
  }

  const visibleColumnsModel = useMemo(
    () => getVisibilityModel(config.visibleColumns, columns),
    [config.visibleColumns, columns],
  )

  return (
    <Box className="filter">
      <Box className="component-title-wrapper">
        <Box className="component-title">
          <Typography variant="h2">{t(`messages:compliance.menu.cases`)}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <CaseSearch
            defaultValues={{
              searchType: queryState.searchType,
              searchText: queryState.searchText,
            }}
            handleSearch={handleSearch}
          />
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{ 'aria-label': 'controlled' }}
                checked={queryState.isMyCases}
                onChange={handleToogleShowMyCases}
              />
            }
            label={t('common:myCases')}
          />
        </Box>
      </Box>
      <Paper elevation={0}>
        <Box style={{ width: '100%', height: '100%' }}>
          <Box className={styles.headerTable}>
            <Box style={{ display: 'flex', gap: '0.5rem' }}>
              <Button variant="label" endIcon={<MenuOutlinedIcon />} onClick={() => setFiltersOpen(true)}>
                <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                  {t('common:filters')}
                  {hasFiltersApplied && <CircleIcon sx={{ color: '#0dde4e', fontSize: '0.6rem' }} />}
                </Box>
              </Button>
              <Box style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                <FilterMenu
                  onApply={handleDispatchStatus}
                  onReset={handleResetFilterStatus}
                  label={
                    <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                      {t('common:status')}
                      {queryState.status !== '' && <CircleIcon sx={{ color: '#0dde4e', fontSize: '0.6rem' }} />}
                    </Box>
                  }
                  options={statuses}
                  defaultValue={queryState.status}
                />
                <FilterMenu
                  onApply={handleDispatchAlertAction}
                  onReset={handleResetFilterActionAlert}
                  label={
                    <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                      {t('common:action')}
                      {queryState.alertAction !== '' && <CircleIcon sx={{ color: '#0dde4e', fontSize: '0.6rem' }} />}
                    </Box>
                  }
                  options={alertActions}
                  defaultValue={queryState.alertAction}
                />
              </Box>
            </Box>
            <TablePagination
              labelRowsPerPage={[]}
              component="div"
              count={allCases?.pagination?.totalElements || 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={config.rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton
              showLastButton
            />
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <Tooltip title={t('common:edit')} arrow disableInteractive>
                <span>
                  <IconButton
                    disabled={Object.keys(selectedCases).length === 0}
                    sx={{
                      color: !selectedCases ? 'grey.500' : '#00C43D',
                    }}
                    onClick={() => setOpenEditCasesForm(true)}
                  >
                    <Edit />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={t('common:showHideColumns')} arrow>
                <Button
                  variant="text"
                  aria-label={t('common:showHideColumns')}
                  onClick={() => apiRef.current.showPreferences('columns')}
                  sx={{
                    padding: 2,
                    justifyContent: 'center',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  <Visibility fontSize="medium" sx={{ color: '#00C43D' }} />
                </Button>
              </Tooltip>
              <Tooltip title={t('common:refresh')} arrow>
                <IconButton onClick={refetch} disabled={isLoadingCases || isFetchingCases}>
                  <RefreshIcon color="action" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box className={styles.subHeaderTable}>
            {t('messages:compliance.cases.selected.items', { items: selectedCases.length })}
            <Tooltip title={t('common:clearAll')} arrow>
              <IconButton onClick={() => handleUpdateSelected([])} disabled={!selectedCases.length}>
                <ClearIcon color={selectedCases.length ? 'action' : 'gray'} />
              </IconButton>
            </Tooltip>
          </Box>
          <DataGridPro
            getRowHeight={() => 'auto'}
            getRowId={(row) => row._id || row.id}
            rows={Array.isArray(allCases?.data) ? allCases.data : []}
            columns={orderedColumns}
            apiRef={apiRef}
            slots={{
              columnMenu: CustomColumnMenu,
              loadingOverlay: CustomLoadingOverlay,
            }}
            hideFooter={true}
            autoHeight
            sortingMode="server"
            loading={isLoadingCases || isFetchingCases || configLoading}
            sortModel={config.sortModel}
            onSortModelChange={(newSortModel) => saveConfig({ sortModel: newSortModel })}
            columnOrder={config.columnOrder}
            onColumnOrderChange={handleColumnOrderChange}
            pinnedColumns={config.pinnedColumns}
            onPinnedColumnsChange={(newPinnedColumns) => saveConfig({ pinnedColumns: newPinnedColumns })}
            columnVisibilityModel={visibleColumnsModel}
            onColumnVisibilityModelChange={(newModel) => saveConfig({ visibleColumns: newModel })}
            checkboxSelection
            keepNonExistentRowsSelected
            onRowSelectionModelChange={handleUpdateSelected}
            rowSelectionModel={selectedCases}
          />
        </Box>
      </Paper>

      <Modal open={filtersOpen} onClose={() => setFiltersOpen(false)}>
        <div>
          <FiltersCasesForm
            defaultValues={{
              caseId: queryState.caseId,
              fromDate: defaultFromDate || null,
              fromTime: defaultFromDate ? defaultFromDate.format('HH:mm') : '00:00',
              toDate: defaultToDate || null,
              toTime: defaultToDate ? defaultToDate.format('HH:mm') : '23:59',
              userId: queryState.userId,
              firstName: queryState.firstName,
              legalName: queryState.legalName,
              documentNumber: queryState.documentNumber,
              userType: queryState.userType,
              userKey: queryState.userKey,
              userValue: queryState.userValue,
              transactionKey: queryState.transactionKey,
              transactionValue: queryState.transactionValue,
              ruleId: queryState.ruleId,
              ruleInstanceId: queryState.ruleInstanceId,
            }}
            setModalOpen={setFiltersOpen}
            handleResetButton={handleResetButton}
            handleDispatch={handleDispatch}
            setPage={setPage}
          />
        </div>
      </Modal>

      <Modal open={!!openEditCasesForm} onClose={() => setOpenEditCasesForm()}>
        <div>
          <EditCasesForm
            setModalOpen={setOpenEditCasesForm}
            casesToEdit={selectedCases}
            onSubmit={handleUpdateCases}
            isLoading={isUpdatingCases}
          />
        </div>
      </Modal>
    </Box>
  )
}

export default CasesPage
