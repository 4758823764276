import './kytManualCheck.scss'

import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import TagsManualCheck from './tagsManualCheck'
import CardDetails from './paymentDetails/CardDetails'
import GenericBank from './paymentDetails/GenericBank'
import IbanDetails from './paymentDetails/IbanDetails'
import AchDetails from './paymentDetails/AchDetails'
import SwiftDetails from './paymentDetails/SwiftDetails'
import MpesaDetails from './paymentDetails/MpesaDetails'
import UpiDetails from './paymentDetails/UpiDetails'
import WalletDetails from './paymentDetails/WalletDetails'
import CheckDetails from './paymentDetails/CheckDetails'
import UserSearch from './UserSearch'
import AmountDetails from './AmountDetails'
import DeviceData from './DevideData'
import ProcessingSpinner from '../../../components/common/alertFetchSpinner/processingSpinner'
import { RenderTooltip } from '../../../components/utilities/RenderTooltip'
import PaymentMethodDetails from './paymentDetails/PaymentMethodDetails'
import paymentMethods from './paymentDetails/PaymentMethods.json'

const types = ['DEPOSIT', 'TRANSFER', 'EXTERNAL_PAYMENT', 'WITHDRAWAL', 'REFUND', 'DEBIN', 'OTHER']
const transactionStates = [
  'CREATED',
  'PROCESSING',
  'SENT',
  'EXPIRED',
  'DECLINED',
  'SUSPENDED',
  'REFUNDED',
  'SUCCESSFUL',
]
export const methods = [
  'CARD',
  'GENERIC_BANK_ACCOUNT',
  'IBAN',
  'ACH',
  'SWIFT',
  'MPESA',
  'UPI',
  'WALLET',
  'CHECK',
  'CBU',
  'CVU',
  'ECHECK',
  'DEBIN',
  'QR_CODE',
  'ONLINE_PAYMENT',
]

export const externalMethods = ['CBU', 'CVU', 'ECHECK', 'DEBIN', 'QR_CODE', 'ONLINE_PAYMENT']

const TransactionManualCheck = () => {
  const { accessToken } = useSelector((state) => state.login)
  const [isFetching, setIsFetching] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()
  const [finish, setFinish] = useState(false)
  const [result, setResult] = useState({})
  const { t } = useTranslation(['common', 'messages'])
  //atributos:
  const [transactionId, setTransactionId] = useState('')
  const [type, setType] = useState('')
  const [timestamp, setTimestamp] = useState('')
  const [transactionState, setTransactionState] = useState('')
  const [originUserId, setOriginUserId] = useState('')
  const [destinationUserId, setDestinationUserId] = useState('')
  const [originAmountDetails, setOriginAmountDetails] = useState({})
  const [destinationAmountDetails, setDestinationAmountDetails] = useState({})
  const [externalOrigin, setExternalOrigin] = useState(false)
  const [externalDestination, setExternalDestination] = useState(false)
  //pdetails
  const [oMethod, setOMethod] = useState('')
  const [dMethod, setDMethod] = useState('')
  const [originPaymentDetails, setOriginPaymentDetails] = useState({})
  const [destinationPaymentDetails, setDestinationPaymentDetails] = useState({})
  const [productType, setProductType] = useState('')
  const [mcc, setMcc] = useState('')
  const [reference, setReference] = useState('')
  const [originDeviceData, setOriginDeviceData] = useState({})
  const [destinationDeviceData, setDestinationDeviceData] = useState({})

  const [tags, setTags] = useState([])
  const [isReset, setIsReset] = useState(false)

  const handleSubmit = () => {
    setIsFetching(true)
    setFinish(false)
    setErrorMessage('')

    if (!process.env.REACT_APP_IS_DEMO) {
      const body = {
        ...(type && { type }),
        ...(transactionId && { transactionId }),
        ...(transactionState && { transactionState }),
        ...(originUserId && originUserId !== '' && { originUserId }),
        ...(destinationUserId && destinationUserId !== '' && { destinationUserId }),
        ...(originPaymentDetails && { originPaymentDetails }),
        ...(destinationPaymentDetails && { destinationPaymentDetails }),
        ...(originAmountDetails && { originAmountDetails }),
        ...(destinationAmountDetails && { destinationAmountDetails }),
        ...(externalOrigin && { externalOrigin }),
        ...(externalDestination && { externalDestination }),
        ...(productType && { productType }),
        ...(mcc && { mcc }),
        ...(reference && { reference }),
        ...(originDeviceData && { originDeviceData }),
        ...(destinationDeviceData && { destinationDeviceData }),
      }
      if (!Object.keys(body.originPaymentDetails).length) delete body.originPaymentDetails
      if (!Object.keys(body.destinationPaymentDetails).length) delete body.destinationPaymentDetails
      if (!Object.keys(body.originAmountDetails).length) delete body.originAmountDetails
      if (!Object.keys(body.destinationAmountDetails).length) delete body.destinationAmountDetails
      if (!Object.keys(body.originDeviceData).length) delete body.originDeviceData
      if (!Object.keys(body.destinationDeviceData).length) delete body.destinationDeviceData

      let url = `${process.env.REACT_APP_BASEURL}/kyt/verifyTransaction`
      const options = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }
      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          setIsFetching(false)
          if (res.success && res.data) {
            setResult(res.data)
            setFinish(true)
          } else {
            throw new Error(
              res.data?.responseBody?.message
                ? res.data?.responseBody?.message
                : res.data?.info
                ? res.data?.info
                : typeof res.data === 'string'
                ? res.data
                : res.message
                ? res.message
                : 'Unexpected',
            )
          }
        })
        .catch((err) => {
          console.error('[VERIFY TRANSACTION REPORT ERROR] --> ', err)
          setIsFetching(false)
          setErrorMessage(err.message ? err.message : t('common:unexpectedErrorValues'))
        })
    } else {
      setIsFetching(false)
      setResult({
        transactionId: '650b417f83a0dcacb969eb2a',
        hitRules: [
          {
            ruleId: 'R-2',
          },
          {
            ruleId: 'RG-2',
          },
        ],
      })
      setFinish(true)
    }
  }

  const handleReset = () => {
    setTransactionId('')
    setType('')
    setTimestamp('')
    setTransactionState('')
    setOriginUserId('')
    setDestinationUserId('')
    setOriginAmountDetails({})
    setDestinationAmountDetails({})
    setOMethod('')
    setDMethod('')
    setOriginPaymentDetails({})
    setDestinationPaymentDetails({})
    setOriginDeviceData({})
    setDestinationDeviceData({})
    setProductType('')
    setReference('')

    setTags('')
    setIsReset(true)
  }

  return (
    <Box className="kyt-manual">
      <Box className="kyt-form kyt-manual-overflow">
        <Box className="kyt-form-pair">
          <Box className="kyt-form-pair-label">
            <InputLabel>{t('common:transactionId')}</InputLabel>
            <RenderTooltip title={'Unique transaction ID.'} />
          </Box>
          <TextField
            fullWidth
            size="small"
            type="text"
            value={transactionId}
            onChange={(event) => setTransactionId(event.target.value)}
            placeholder={`${t('common:insert')} ${t('common:transactionId')} ${t('here')}`}
          />
        </Box>

        <Box className="kyt-form-pair">
          <Box className="kyt-form-pair-label">
            <InputLabel>{t('common:type')}</InputLabel>
            <RenderTooltip title={'Transaction type.'} />
          </Box>
          <Select size="small" fullWidth value={type} onChange={(e) => setType(e.target.value)} displayEmpty>
            <MenuItem disabled value="">
              <Typography variant="subtitle3">
                {t('common:select')} {t('common:type')}
              </Typography>
            </MenuItem>
            {types.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box className="kyt-form-pair">
          <Box className="kyt-form-pair-label">
            <InputLabel>{t('common:timestamp')}</InputLabel>
            <RenderTooltip title={'Timestamp of when transaction took place >= 1262300400000.'} />
          </Box>
          <TextField
            fullWidth
            type="number"
            placeholder={`${t('common:insert')} ${t('common:timestamp')} ${t('here')}`}
            size="small"
            value={timestamp}
            onChange={(event) => setTimestamp(event.target.value !== '' ? parseInt(event.target.value) : '')}
          />
        </Box>

        <Box className="kyt-form-pair">
          <Box className="kyt-form-pair-label">
            <InputLabel>{t('common:transactionState')}</InputLabel>
            <RenderTooltip title={'Model for transaction states. E.g. Processing, Refunded, Successful etc.'} />
          </Box>

          <Select
            size="small"
            fullWidth
            value={transactionState}
            onChange={(e) => setTransactionState(e.target.value)}
            displayEmpty
          >
            <MenuItem disabled value="">
              <Typography variant="subtitle3">
                {t('common:select')} {t('common:transactionState')}
              </Typography>
            </MenuItem>
            {transactionStates.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box className="kyt-form-pair">
          <Box className="kyt-form-pair-label">
            <InputLabel>{t('common:originUserId')}</InputLabel>
            <RenderTooltip title={'UserId for where the transaction originates from.'} />
          </Box>

          <UserSearch selectedUser={originUserId} setSelectedUser={setOriginUserId} />
        </Box>

        <Box className="kyt-form-pair">
          <Box className="kyt-form-pair-label">
            <InputLabel>{t('common:destinationUserId')}</InputLabel>
            <RenderTooltip
              title={'UserId for transactions destination. In other words, where the value is being transferred to.'}
            />
          </Box>

          <UserSearch selectedUser={destinationUserId} setSelectedUser={setDestinationUserId} />
        </Box>

        <Box className="kyt-form-pair">
          <Divider style={{ margin: '1rem 0rem' }} />

          <Box className="kyt-form-pair-label" sx={{ marginBottom: '.5rem' }}>
            <Typography variant="title1">{t('common:originAmountDetails')}</Typography>
          </Box>

          <AmountDetails setAmountDetails={setOriginAmountDetails} isReset={isReset} setIsReset={setIsReset} />
        </Box>

        <Box className="kyt-form-pair">
          <Divider style={{ margin: '1rem 0rem' }} />
          <Box className="kyt-form-pair-label" sx={{ marginBottom: '.5rem' }}>
            <Typography variant="title1">{t('common:destinationAmountDetails')}</Typography>
          </Box>
          <AmountDetails setAmountDetails={setDestinationAmountDetails} isReset={isReset} setIsReset={setIsReset} />
        </Box>

        <Box className="kyt-form-pair">
          <Box className="kyt-form-pair-label">
            <InputLabel>{t('common:oMethod')}</InputLabel>
            <RenderTooltip title={'Model for transaction states. E.g. Processing, Refunded, Successful etc.'} />
          </Box>
          <Select size="small" fullWidth value={oMethod} onChange={(e) => setOMethod(e.target.value)} displayEmpty>
            <MenuItem disabled value="">
              <Typography variant="subtitle3">
                {t('common:select')} {t('common:oMethod')}
              </Typography>
            </MenuItem>
            {methods.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
          {oMethod !== '' && (
            <Box>
              {oMethod === 'CARD' && (
                <CardDetails setPaymentDetails={setOriginPaymentDetails} isReset={isReset} setIsReset={setIsReset} />
              )}
              {oMethod === 'GENERIC_BANK_ACCOUNT' && (
                <GenericBank setPaymentDetails={setOriginPaymentDetails} isReset={isReset} setIsReset={setIsReset} />
              )}
              {oMethod === 'IBAN' && (
                <IbanDetails setPaymentDetails={setOriginPaymentDetails} isReset={isReset} setIsReset={setIsReset} />
              )}
              {oMethod === 'ACH' && (
                <AchDetails setPaymentDetails={setOriginPaymentDetails} isReset={isReset} setIsReset={setIsReset} />
              )}
              {oMethod === 'SWIFT' && (
                <SwiftDetails setPaymentDetails={setOriginPaymentDetails} isReset={isReset} setIsReset={setIsReset} />
              )}
              {oMethod === 'MPESA' && (
                <MpesaDetails setPaymentDetails={setOriginPaymentDetails} isReset={isReset} setIsReset={setIsReset} />
              )}
              {oMethod === 'UPI' && (
                <UpiDetails setPaymentDetails={setOriginPaymentDetails} isReset={isReset} setIsReset={setIsReset} />
              )}
              {oMethod === 'WALLET' && (
                <WalletDetails setPaymentDetails={setOriginPaymentDetails} isReset={isReset} setIsReset={setIsReset} />
              )}
              {oMethod === 'CHECK' && (
                <CheckDetails setPaymentDetails={setOriginPaymentDetails} isReset={isReset} setIsReset={setIsReset} />
              )}
              {externalMethods.includes(oMethod) && (
                <PaymentMethodDetails
                  paymentMethod={oMethod}
                  paymentMethodAtributes={paymentMethods[oMethod]}
                  setPaymentDetails={setOriginPaymentDetails}
                  isReset={isReset}
                  setIsReset={setIsReset}
                />
              )}
            </Box>
          )}
        </Box>

        <Box className="kyt-form-pair">
          <Box className="kyt-form-pair-label">
            <InputLabel>{t('common:dMethod')}</InputLabel>
            <RenderTooltip title={'Model for transaction states. E.g. Processing, Refunded, Successful etc.'} />
          </Box>
          <Select size="small" fullWidth value={dMethod} onChange={(e) => setDMethod(e.target.value)} displayEmpty>
            <MenuItem disabled value="">
              <Typography variant="subtitle3">
                {t('common:select')} {t('common:dMethod')}
              </Typography>
            </MenuItem>
            {methods.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
          {dMethod !== '' && (
            <Box>
              {dMethod === 'CARD' && (
                <CardDetails
                  setPaymentDetails={setDestinationPaymentDetails}
                  isReset={isReset}
                  setIsReset={setIsReset}
                />
              )}
              {dMethod === 'GENERIC_BANK_ACCOUNT' && (
                <GenericBank
                  setPaymentDetails={setDestinationPaymentDetails}
                  isReset={isReset}
                  setIsReset={setIsReset}
                />
              )}
              {dMethod === 'IBAN' && (
                <IbanDetails
                  setPaymentDetails={setDestinationPaymentDetails}
                  isReset={isReset}
                  setIsReset={setIsReset}
                />
              )}
              {dMethod === 'ACH' && (
                <AchDetails
                  setPaymentDetails={setDestinationPaymentDetails}
                  isReset={isReset}
                  setIsReset={setIsReset}
                />
              )}
              {dMethod === 'SWIFT' && (
                <SwiftDetails
                  setPaymentDetails={setDestinationPaymentDetails}
                  isReset={isReset}
                  setIsReset={setIsReset}
                />
              )}
              {dMethod === 'MPESA' && (
                <MpesaDetails
                  setPaymentDetails={setDestinationPaymentDetails}
                  isReset={isReset}
                  setIsReset={setIsReset}
                />
              )}
              {dMethod === 'UPI' && (
                <UpiDetails
                  setPaymentDetails={setDestinationPaymentDetails}
                  isReset={isReset}
                  setIsReset={setIsReset}
                />
              )}
              {dMethod === 'WALLET' && (
                <WalletDetails
                  setPaymentDetails={setDestinationPaymentDetails}
                  isReset={isReset}
                  setIsReset={setIsReset}
                />
              )}
              {dMethod === 'CHECK' && (
                <CheckDetails
                  setPaymentDetails={setDestinationPaymentDetails}
                  isReset={isReset}
                  setIsReset={setIsReset}
                />
              )}
              {externalMethods.includes(dMethod) && (
                <PaymentMethodDetails
                  paymentMethod={dMethod}
                  paymentMethodAtributes={paymentMethods[dMethod]}
                  setPaymentDetails={setDestinationPaymentDetails}
                  isReset={isReset}
                  setIsReset={setIsReset}
                />
              )}
            </Box>
          )}
        </Box>

        <Box className="kyt-form-pair">
          <Box className="kyt-form-pair-label">
            <InputLabel>{t('common:productType')}</InputLabel>
            <RenderTooltip title={'Type of produce being used by the consumer (ex wallets, payments etc).'} />
          </Box>
          <TextField
            fullWidth
            size="small"
            type="text"
            value={productType}
            onChange={(event) => setProductType(event.target.value)}
            placeholder={`${t('common:insert')} ${t('common:productType')} ${t('here')}`}
          />
        </Box>

        <Box className="kyt-form-pair">
          <Box className="kyt-form-pair-label">
            <InputLabel>{t('common:reference')}</InputLabel>
            <RenderTooltip
              title={'Reference field for the transaction indicating the purpose of the transaction etc.'}
            />
          </Box>
          <TextField
            fullWidth
            size="small"
            type="text"
            value={reference}
            onChange={(event) => setReference(event.target.value)}
            placeholder={`${t('common:insert')} ${t('common:reference')} ${t('here')}`}
          />
        </Box>

        <Box className="kyt-form-pair">
          <Divider style={{ margin: '1rem 0rem' }} />
          <Box className="kyt-form-pair-label" sx={{ marginBottom: '.5rem' }}>
            <Typography variant="title1">{t('common:originDeviceData')}</Typography>
            <RenderTooltip title={'Model for device data.'} />
          </Box>

          <DeviceData setDeviceData={setOriginDeviceData} isReset={isReset} setIsReset={setIsReset} />
        </Box>

        <Box className="kyt-form-pair">
          <Divider style={{ margin: '1rem 0rem' }} />
          <Box className="kyt-form-pair-label" sx={{ marginBottom: '.5rem' }}>
            <Typography variant="title1">{t('common:destinationDeviceData')}</Typography>
            <RenderTooltip title={'Model for device data.'} />
          </Box>

          <DeviceData setDeviceData={setDestinationDeviceData} isReset={isReset} setIsReset={setIsReset} />
        </Box>

        <Box className="kyt-form-pair">
          <Box display="flex" gap="1rem">
            <Paper elevation={0}>
              <Box>
                <Box className="required">
                  <InputLabel>{t('common:externalOrigin')}</InputLabel>
                  <RenderTooltip title={t('common:externalOriginTooltip')} />
                </Box>

                <FormControlLabel
                  control={<Checkbox checked={externalOrigin} />}
                  label={<Typography variant="h5">{t('common:externalOriginLabel')}</Typography>}
                  onChange={() => setExternalOrigin(!externalOrigin)}
                />
              </Box>
            </Paper>
            <Paper elevation={0}>
              <Box>
                <Box className="required">
                  <InputLabel>{t('common:externalDestination')}</InputLabel>
                  <RenderTooltip title={t('common:externalDestinationTooltip')} />
                </Box>

                <FormControlLabel
                  control={<Checkbox checked={externalDestination} />}
                  label={<Typography variant="h5">{t('common:externalDestinationLabel')}</Typography>}
                  onChange={() => setExternalDestination(!externalDestination)}
                />
              </Box>
            </Paper>
          </Box>
        </Box>

        <Box className="kyt-form-pair">
          <Box className="kyt-form-pair-label">
            <InputLabel>{t('common:mcc')}</InputLabel>
            <RenderTooltip title={'MCC value'} />
          </Box>
          <TextField
            fullWidth
            size="small"
            type="text"
            value={mcc}
            onChange={(event) => setMcc(event.target.value)}
            placeholder={`${t('common:insert')} ${t('common:mcc')} ${t('here')}`}
          />
        </Box>

        <Box className="kyt-form-pair">
          <Box className="kyt-form-pair-label">
            <InputLabel>{t('common:tags')}</InputLabel>
            <RenderTooltip title={'Additional information that can be added via tags.'} />
          </Box>
          <TagsManualCheck setRuleParameters={setTags} isReset={isReset} setIsReset={setIsReset} />
        </Box>
      </Box>

      <Box className="modal-filter-buttons">
        <Button
          onClick={() => {
            handleReset()
          }}
          size="small"
          variant="outlinedGrey"
        >
          {t('common:reset')}
        </Button>
        <Button size="small" variant="contained" type="submit" onClick={() => handleSubmit()}>
          {t('common:create')}
        </Button>
      </Box>
      {isFetching && <ProcessingSpinner message={t('common:processing')} />}
      {errorMessage !== '' && (
        <Box className="security-error">
          <Button size="small" variant="outlined" color="error">
            {errorMessage}
          </Button>
        </Box>
      )}
      {result && finish && errorMessage === '' && (
        <Box>
          <Typography variant="title">{t('common:result')}:</Typography>

          <Box className="sub-item">
            <InputLabel>{t('common:referenceId')}</InputLabel>
            <p className="id-action" onClick={() => navigate(`/transactions/${result.transactionId}?type=consumer`)}>
              {result.transactionId ? result.transactionId : '---'}
            </p>
          </Box>
          <Box className="sub-item">
            <InputLabel>{t('common:alerts')}</InputLabel>
            <p>{result?.hitRules?.length}</p>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default TransactionManualCheck
